import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.css'

// component function
export default function Terms(props) {

	const { action } = props
	const [opacity, setOpacity] = useState(0)
	const timeoutID = useRef()

	// dynamic styles
	const overlayStyle = {
		opacity: opacity
	}

	// fade in & set keypress listener
	useEffect(() => {
		timeoutID.current = setTimeout(function() { // delay - otherwise no opacity transition
			setOpacity(1)
		}, 200)
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// handle button click
	function performAction(e,b) {
		e.nativeEvent.stopImmediatePropagation()
		action(b)
	}

	const titleDiv = <h4 className={styles.title}>You must accept the Terms & conditions<br/>before you can use flowsam</h4>
	const messageDiv = <div className={styles.message}>Please read the <a href="https://flowsam.ai/terms/" className={styles.link} target="_blank" rel="noopener noreferrer">Terms & conditions</a> before you accept.</div>
	const buttonCancel = <button className={'fs-button ' + styles.button + ' ' + styles.cancelButton} tabIndex="-1" onClick={e=>performAction(e,false)}>NO, LOG ME OUT</button>
	const buttonOk = <button className={'fs-button ' + styles.button} tabIndex="-1" onClick={e=>performAction(e,true)}>YES, I ACCEPT</button>

	return (
		<div id="overlay" className={styles.overlay} style={overlayStyle}>
			<div className={styles.messageContainer}>
				{titleDiv}
				{messageDiv}
				<div className={styles.buttonContainer}>{buttonCancel}{buttonOk}</div>
			</div>
		</div>
	)
}
