import React from 'react'
import CreatableSelect from 'react-select/creatable'

// component function
export default function DropDown(props) {

	const { value, options, onChange, placeholder, searchable, small=false, width='100%' } = props

	// styles
	const customStyles = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? 'var(--input-highlight-color)' : 'var(--input-back-color)',
			color: state.isFocused ? 'var(--text-color)' : 'var(--input-placeholder-color)',
			fontSize: small ? '14px' : '18px',
			height: small ? '31px' : '40px',
			padding: small ? '7px 0 0 12px' : '10px 0 0 20px',
			textAlign: 'left',
			'&:active': {
				backgroundColor: 'var(--input-highlight-color)'
			}
		}),
		control: (provided) => ({
			...provided,
			border: 0,
			borderRadius: 8,
			borderBottom: '1px var(--input-border-color) solid',
			backgroundColor: 'var(--input-back-color)',
			fontSize: small ? '14px' : '18px',
			height: small ? '31px' : '47px',
			minHeight: '31px',
			padding: small ? '0 10px 0 4px' : '1px 10px 0 12px',
			boxShadow: 'none',
			'&:hover': {
				borderBottom: '1px var(--input-border-color) solid'
			}
		}),
		input: (provided) => ({
			...provided,
			color: 'var(--text-color)',
			marginLeft: 0
		}),
		singleValue: (provided) => ({
			...provided,
			color: 'var(--text-color)',
			marginLeft: 0
		}),
		menu: (provided) => ({
			...provided,
			marginTop: 0,
			borderRadius: 0,
			backgroundColor: 'var(--input-back-color)',
			boxShadow: 'none',
			whiteSpace: 'nowrap',
			width: width
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'var(--input-placeholder-color)',
			marginLeft: 0
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			color: 'var(--input-placeholder-color)',
			display: searchable ? 'none' : 'block',
			padding: small ? '4px 0 0' : '4px 7px 0 0'
		}),
		indicatorSeparator: () => ({
			display: 'none'
		})
	}

	return (
		<CreatableSelect
			value={value}
			styles={customStyles}
			onChange={(selectedOption) => onChange(searchable ? selectedOption.label : selectedOption.value)}
			options={options}
			placeholder={placeholder}
			isSearchable={searchable}
		/>
	)
}
