import React, { useState, useEffect, useContext, useRef } from 'react'
import { APIContext } from '../../utils/api'
import { industries, getIndustryBySubIndustryId } from '../../utils/industries'
import { mediaTypes } from '../../utils/mediaTypes'
import AnalysisListItem from'./AnalysisListItem'
import styles from './styles.module.css'

// component function
export default function AnalysisList() {

	const context = useContext(APIContext)
	const [itemList, setItemList] = useState()
	const [searchInput, setSearchInput] = useState('')
	const [forceUpdate, setForceUpdate] = useState(0) // eslint-disable-line no-unused-vars
	const itemsRef = useRef()
	const itemsFilteredRef = useRef()
	const industryFilterRef = useRef()
	const yearFilterRef = useRef()
	const statusFilterRef = useRef()
	const mediaTypeFilterRef = useRef()
	const searchInputRef = useRef('')

	// init on mount
	useEffect(() => {
		//keyboard esc listener
		window.addEventListener("keydown", keyPressed)
		return () => window.removeEventListener("keydown", keyPressed) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// get analyses list
	useEffect(() => {
		context.io.socket.get('/api/v1/video', (data, res) => {
			if (res.statusCode === 200) {
				itemsRef.current = data.sort((a, b) => b.createdAt - a.createdAt)
				itemsFilteredRef.current = data.sort((a, b) => b.createdAt - a.createdAt)
				const list = itemsRef.current.map((item,i) => {
					return <AnalysisListItem
						key={item.id}
						id={item.id}
						idx={i}
						item={item}
						removeItem={removeItem}
						updateFilters={filterOnIndustryYearMediaType}
					/>
				})
				setItemList(list)
				adjustSelectWidth(industryFilterRef.current)
				adjustSelectWidth(yearFilterRef.current)
				adjustSelectWidth(statusFilterRef.current)
				adjustSelectWidth(mediaTypeFilterRef.current)
			} else {
				// TODO: error handling
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket])

	// clear search field on escape
	function keyPressed(e) {
		e.keyCode === 27 && updateSearch('') // escape
	}

	// filter list on search input
	function updateSearch(txt) {
		setSearchInput(txt)
		searchInputRef.current = txt
		filterOnIndustryYearMediaType()	
	}

	// filter on selected industry, year, status, media type and search input
	function filterOnIndustryYearMediaType() {
		const iSel = industryFilterRef.current
		const ySel = yearFilterRef.current
		const sSel = statusFilterRef.current
		const mSel = mediaTypeFilterRef.current
		if (itemsRef.current.find(i => getIndustryBySubIndustryId(i.industrySubCode)?.id === parseInt(iSel.value)) === undefined) industryFilterRef.current.selectedIndex = 0  // no possible items for selected industry filter - reset filter
		if (itemsRef.current.find(i => i.targetPlatform === parseInt(mSel.value)) === undefined) mediaTypeFilterRef.current.selectedIndex = 0  // no possible items for selected media type filter - reset filter
		const industries = iSel.value ? itemsRef.current.filter(o => getIndustryBySubIndustryId(o.industrySubCode)?.id === parseInt(iSel.value)) : itemsRef.current
		const industriesYears = ySel.value ? industries.filter(o => new Date(o.createdAt).getFullYear() === parseInt(ySel.value)) : industries
		const industriesYearsStatus = sSel.value ?
			sSel.value === 'ready' ? industriesYears.filter(o => o.currentState === 'complete' && o.frontendState === null) : // analysis completed but tagging not started
			sSel.value === 'processing' ? industriesYears.filter(o => o.currentState === 'processing') : // currently analyzing
			industriesYears.filter(o => o.frontendState?.status === sSel.value) : // tagging completed or in progress
			industriesYears // no status filter
		const industriesYearsStatusTypes = mSel.value ? industriesYearsStatus.filter(o => o.targetPlatform === parseInt(mSel.value)) : industriesYearsStatus
		// search filter
		itemsFilteredRef.current = searchInputRef.current !== '' ? industriesYearsStatusTypes.filter(item =>
			(item.title?.toLowerCase().indexOf(searchInputRef.current.toLowerCase()) > -1) ||
			(item.project?.name?.toLowerCase().indexOf(searchInputRef.current.toLowerCase()) > -1) ||
			(item.brand?.name?.toLowerCase().indexOf(searchInputRef.current.toLowerCase()) > -1) ||
			(item.subbrand?.toLowerCase().indexOf(searchInputRef.current.toLowerCase()) > -1)
		) : industriesYearsStatusTypes
		adjustSelectWidth(iSel)
		adjustSelectWidth(ySel)
		adjustSelectWidth(sSel)
		adjustSelectWidth(mSel)
		filterList()
		setForceUpdate(Math.random()) // render filter menus so selected style can update
	}

	// filter list // DOM filtering instead of react by design to preserve server feedback listeners on 'Analyzing' items
	function filterList() {
		itemsRef.current.map(item => {
			document.getElementById('item-'+item.id).style.display = 'none'
			return 0
		})
		itemsFilteredRef.current.map(item => {
			document.getElementById('item-'+item.id).style.display = 'block'
			return 0
		})
	}

	// adapt select menu width to fit selected text
	function adjustSelectWidth(sel) {
		const measure = document.getElementById('measureWidth')
		measure.style.fontFamily = sel.selectedIndex > 0 ? 'Greycliff demibold' : 'Greycliff'
		measure.innerHTML = sel.options[sel.selectedIndex].text
		sel.style.width = measure.offsetWidth + 25.5 + 'px'
	}

	// remove item from list/DOM if deleted from backend
	function removeItem(id) {
		itemsRef.current = itemsRef.current.filter(item => item.id !== id)
		itemsFilteredRef.current = itemsFilteredRef.current.filter(item => item.id !== id)
		document.getElementById('item-'+id).remove()
		filterOnIndustryYearMediaType() // update filters after delete
	}

	// reset filter menus
	function resetFilters() {
		industryFilterRef.current.selectedIndex = 0
		yearFilterRef.current.selectedIndex = 0
		statusFilterRef.current.selectedIndex = 0
		mediaTypeFilterRef.current.selectedIndex = 0
		updateSearch('')
	}

	// search input field
	const searchField = (
		<>
			<input className={styles.searchInput}
				name="search"
				tabIndex="-1"
				spellCheck="false"
				value={searchInput}
				onChange={e=>updateSearch(e.target.value)}
				title="Filter on title, brand, sub brand and project"
			/>
			{searchInput !== '' ?
				<div id="clearButton" className={styles.clearButton} onClick={e=>updateSearch('')}>&times;</div> :
				<div id="searchIcon" className={styles.searchIcon}>&#9906;</div>
			}
		</>
	)

	// find and sort industry codes and analysis types in list items
	const usedIndustryCodes = [...new Set(itemsRef.current?.map(a => a.industrySubCode).sort((a, b) => (a - b)))]
	const usedYears = [...new Set(itemsRef.current?.map(a => new Date(a.createdAt).getFullYear()).sort((a, b) => (b - a)))] // years desc
	const usedVideoMediaTypes = [...new Set(itemsRef.current?.filter(a=> a.analysisType < 3).map(a => a.targetPlatform).sort((a, b) => (a - b)))] // find used video media types
	const usedImageMediaTypes = [...new Set(itemsRef.current?.filter(a=> a.analysisType === 3).map(a => a.targetPlatform).sort((a, b) => (a - b)))] // find used image media types

	const filtersActive = industryFilterRef.current?.selectedIndex + yearFilterRef.current?.selectedIndex + statusFilterRef.current?.selectedIndex + mediaTypeFilterRef.current?.selectedIndex > 0 || searchInputRef.current !== ''

	// list item filters
	const filters = (
		<div className={styles.filters}>
			<div className={styles.industryYearFilter}>
				{itemsRef.current && (
					<div className={styles.menuGroupWrapper}>
						<div className={styles.selectWrapper}>
							<select ref={industryFilterRef} className={styles.select + ' ' + (industryFilterRef.current?.selectedIndex !== undefined && industryFilterRef.current?.selectedIndex !== 0 ? styles.selected : '')} title="Filter on industry" onChange={filterOnIndustryYearMediaType}>
								<option value=''>All industries</option>
								{industries.filter(ind => ind.subindustries.find(sub => usedIndustryCodes.indexOf(sub.id) > -1)).map((ind,i) => {
									return(<option key={'industry-'+i} value={ind.id}>{ind.label}</option>)
								})}
							</select>
						</div>
						<div className={styles.selectWrapper}>
							<select ref={yearFilterRef} className={styles.select + ' ' + (yearFilterRef.current?.selectedIndex !== undefined && yearFilterRef.current?.selectedIndex !== 0 ? styles.selected : '')} title="Filter on year" onChange={filterOnIndustryYearMediaType}>
								<option value=''>All years</option>
								{usedYears.map((i) => {
									return(<option key={'year-'+i} value={i}>{i}</option>)
								})}
							</select>
						</div>
					</div>
				)}
			</div>
			<div className={styles.mediaTypeFilter}>
				{itemsRef.current && (
					<div className={styles.menuGroupWrapper}>
						<div className={styles.selectWrapper}>
							<select ref={statusFilterRef} className={styles.select + ' ' + (statusFilterRef.current?.selectedIndex !== undefined && statusFilterRef.current?.selectedIndex !== 0 ? styles.selected : '')} title="Filter on status" onChange={filterOnIndustryYearMediaType}>
								<option value=''>All statuses</option>
								<option value='finished'>Report ready</option>
								<option value='ready'>Ready to tag</option>
								<option value='work-in-progress'>Being tagged</option>
								<option value='processing'>Analyzing</option>
							</select>
						</div>
						<div className={styles.selectWrapper}>
							<select ref={mediaTypeFilterRef} className={styles.select + ' ' + (mediaTypeFilterRef.current?.selectedIndex !== undefined && mediaTypeFilterRef.current?.selectedIndex !== 0 ? styles.selected : '')} title="Filter on media type" onChange={filterOnIndustryYearMediaType}>
								<option value=''>All media types</option>
								{usedVideoMediaTypes.length > 0 && <optgroup label="Video">
									{mediaTypes.filter(at => usedVideoMediaTypes.indexOf(at.id) > -1).map((o,i) => {
										return(<option key={'videomediatype-'+i} value={o.id}>{o.label}</option>)
									})}
								</optgroup>}
								{usedImageMediaTypes.length > 0 && <optgroup label="Image">
									{mediaTypes.filter(at => usedImageMediaTypes.indexOf(at.id) > -1).map((o,i) => {
										return(<option key={'imagemediatype-'+i} value={o.id}>{o.label}</option>)
									})}
								</optgroup>}
							</select>
						</div>
						<div className={styles.reset + ' ' + (filtersActive ? styles.resetActive : '')} onClick={resetFilters} title="Reset filters"/>
					</div>
				)}
			</div>
			<div id='measureWidth' className={styles.selectWidth} />
		</div>
	)

	return (
		<div className={styles.container}>
			<h2>My analyses</h2>
			<div className={styles.searchContainer}>
				{searchField}
			</div>
			{filters}
			<div className={styles.analysisList}>
				{itemList}
			</div>
		</div>
	)
}
