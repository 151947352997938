import React from 'react'
import styles from './styles.module.css'

// component function
export default function AnalysisProgressBar(props) {

	const { label, pct, id } = props

	// dynamic styles
	const progressLabelStyle = {
		opacity: pct === 0 ? '.6' : '1'
	}

	const frontBarStyle = {
		width: pct + '%',
		minWidth: pct > 0 ? '11px' : '0',
		transition: pct > 0 ? 'width 1s' : 'width 0s'
	}

	const barLabel = label && <h4 className={styles.progressLabel} style={progressLabelStyle}>{label}</h4> // + ' ' + Math.round(pct) + '%'}</h4>

	return (
		<div id={id} className={styles.progressContainer}>
			<div className={styles.backBar}>
				<div className={styles.frontBar + ' ' + (pct === 100 ? styles.full : '')} style={frontBarStyle} />
			</div>
			{barLabel}
		</div>
	)
}
