import React, { useState, useContext, useEffect } from 'react'
import { Prompt } from 'react-router'
import { GlobalContext } from '../../utils/globalState'
import styles from './styles.module.css'
import VideoOrImage from './VideoOrImage'
import MediaType from './MediaType'
import Industry from './Industry'
import AdInfo from './AdInfo'
import GetMoreAnalyses from './GetMoreAnalyses'

// component function
export default function AnalysisForm(props) {

	const { action } = props

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [mediaType, setMediaType] = useState()
	const [mediaFile, setMediaFile] = useState()
	const [mediaRatio, setMediaRatio] = useState()
	const [mediaTypeId, setMediaTypeId] = useState()
	const [subIndustryId, setSubIndustryId] = useState()

	const org = globalState.userData?.organization
	const analysesExtra = org?.freeAnalyses ? org?.freeAnalyses : 0
	const analysesSpent = (org?.subscriptionPlan?.id === 9 || org?.subscriptionPlan?.id === 12) && analysesExtra <= 0 // analyses left on Trial or Ad hoc?

	// conditional window unload listener
	useEffect(() => {
		mediaFile !== undefined ? window.addEventListener('beforeunload', leaveWarning) : window.removeEventListener('beforeunload', leaveWarning)
		return () => window.removeEventListener('beforeunload', leaveWarning) // cleanup on unmount
	})

	// warning before leaving if form has changes
	function leaveWarning(e) {
		e.preventDefault()
		e.returnValue = 'If you leave now your form data will be lost'
	}
	
	const uploadField = analysesSpent ? <GetMoreAnalyses /> : <VideoOrImage setMediaType={setMediaType} setMediaFile={setMediaFile} setMediaRatio={setMediaRatio} />

	return (
		<>
			<div className={styles.wrapper}>
				<h2>New analysis</h2>
				{uploadField}
				<MediaType mediaType={mediaType} setMediaTypeId={setMediaTypeId} mediaRatio={mediaRatio} />
				<Industry mediaTypeId={mediaTypeId} setSubIndustryId={setSubIndustryId} />
				<AdInfo mediaFile={mediaFile} mediaTypeId={mediaTypeId} subIndustryId={subIndustryId} submitFormData={action} />
			</div>
			<Prompt when={mediaFile !== undefined} message="If you leave now your form data will be lost" />
		</>
	)
}
