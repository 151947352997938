import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { APIContext } from '../../../utils/api'
import { GlobalContext } from '../../../utils/globalState'
import useLocalStorage from '../../../utils/useLocalStorage'
import ReactCardFlip from 'react-card-flip'
import AnalysisScore from './AnalysisScore'
import AnalysisListItemMenu from './AnalysisListItemMenu'
import AnalysisListItemDetails from './AnalysisListItemDetails'
import AnalysisProgressBar from '../../common/AnalysisProgressBar'
import Alert from '../../common/Alert'
import styles from './styles.module.css'

// component function
export default function AnalysisListItem(props) {

	// progress default object
	const progressInit = {
		todo: { transcode: 1, preProc: 1, objectDetection: 1, objectDetectionPostProc: 1, mapImage: 1, flowsam: 1, mapImageBlend: 1, attentionCalc: 1, statisticsCalc: 1 },
		done: { transcode: 0, preProc: 0, objectDetection: 0, objectDetectionPostProc: 0, mapImage: 0, flowsam: 0, mapImageBlend: 0, attentionCalc: 0, statisticsCalc: 0 }
	}

	const { item, idx, id, removeItem, updateFilters } = props
	const { guid, title, project, brand, currentState, frontendState, scoreClarity, scoreCueTypeCategoryAttention:attention=0, createdAt } = item
	const context = useContext(APIContext)
	const history = useHistory()
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [editDetails, setEditDetails] = useState(false)
	const [progress, setProgress] = useLocalStorage('analysisProgress-' + guid, progressInit)
	const { todo, done } = progress
	const [pctDone, setPctDone] = useState(calculatePctDone(todo, done))
	const [analysisCompleted, setAnalysisCompleted] = useState(false)
	const [bgImage, setBgImage] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const imageLoading = useRef(false)

	const status = frontendState?.status ? frontendState.status : currentState === 'complete' || analysisCompleted ? 'tagging' : 'analyzing'
	//const showTeamAnalyses = globalState.userData?.settings?.showTeamAnalyses ? globalState.userData.settings.showTeamAnalyses : false
	const showTeamAnalyses = false
	const userId = globalState.userData?.id
	const isStillImage = item.analysisType === 3 // still image analysis

	// analysis creation date
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const date = createdAt ? new Date(createdAt).toLocaleDateString("en-GB", dateOptions) : null

	// dynamic styles
	const imageStyle = {
		backgroundImage: 'url(' + (bgImage?.src) + ')',
		backgroundSize: (item.height/item.width) <= .75 ? '155%' : 'cover', // overscale to hide letterbox black bars
	}

	const itemStyle = {
		cursor: status === 'analyzing' ? 'auto' : 'pointer'
	}

	// set up progress listeners, if needed
	useEffect(() => {
		if (status === 'analyzing') {
			// subscribe to progress events for this analysis
			context.io.socket.post('/api/v1/user/subscribe', { roomName: 'video-' + guid }, (data, res) => {
				if (res.statusCode !== 200) {
					// TODO: handle error
				}
			})
			// listen for analysis progress
			context.io.socket.on('video-progress-' + guid, msg => {
				// calculate progress percent
				const pct = calculatePctDone(msg.todo, msg.done)
				setPctDone(pct)
				pct < 100 && setProgress(msg)
				pct === 100 && window.localStorage.removeItem('analysisProgress-' + guid) // remove localStorage item when 100% done
				pct === 100 && setTimeout(() => {
					item.currentState = 'complete'
					setAnalysisCompleted(true)
				}, 1500) // delay so user can see finished progress
			})
		} else {
			window.localStorage.removeItem('analysisProgress-' + guid) // remove leftover localStorage items
		}
		return () => context.io.socket.removeAllListeners('video-progress-' + guid) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[context.io.socket, guid, status])

	// load bg image - and retry if not found (yet)
	if (guid && !imageLoading.current) {
		imageLoading.current = true
		let retries = 0
		const img = new Image()
		const imgSrc = process.env.REACT_APP_GCS_BUCKET_URL + '/' + guid + '/thumb.jpg'
		img.onload = () => setBgImage(img)
		img.onerror = () => setTimeout(() => {
			if (++ retries < 50) {
				img.src = imgSrc
			}
		},5000)
		img.src = imgSrc
	}

	// show analysis date
	function onEnter() {
		document.getElementById('date'+guid).style.opacity = '1'
	}

	// hide analysis date
	function onLeave() {
		document.getElementById('date'+guid).style.opacity = '0'
	}

	// click handler
	function handleClick() {
		if (status === 'tagging' || status === 'work-in-progress') {
			history.push('/' + guid) // go to tagging
		} else if (status === 'finished') {
			history.push('/report/' + guid) // go to report
		}
	}

	// calculate how far the analysis has progressed
	function calculatePctDone(todo,done) {
		return (
			done.transcode/todo.transcode + 
			done.preProc/todo.preProc + 
			done.objectDetection/todo.objectDetection + 
			done.objectDetectionPostProc/todo.objectDetectionPostProc + 
			done.mapImage/todo.mapImage + 
			done.flowsam/todo.flowsam + 
			done.mapImageBlend/todo.mapImageBlend + 
			done.attentionCalc/todo.attentionCalc + 
			done.statisticsCalc/todo.statisticsCalc 
		) / 9 * 100
	}

	const progressLabel = (
		pctDone < 100 ? 
			done.transcode < todo.transcode ? "Transcoding..." :
			done.preProc < todo.preProc ? "Optical flow & frame extraction..." :
			done.objectDetection < todo.objectDetection ? "Object detection..." :
			done.objectDetectionPostProc < todo.objectDetectionPostProc ? "Object detection post processing..." : 
			done.mapImage < todo.mapImage ? "Frame sequence generation..." : 
			done.flowsam < todo.flowsam ? "Attention prediction..." :
			done.mapImageBlend < todo.mapImageBlend ? "Attention heat map generation..." :
			done.attentionCalc < todo.attentionCalc ? "Attention metrics calculation..." :
			done.statisticsCalc < todo.statisticsCalc ? "Crunching numbers..." : "Analyzing..." :
		"Analysis complete"
	)

	const infoContent = (
		status === 'analyzing' ?
		
		// video is analyzing
		<div className={styles.analyzingContainer}>
			<p className={styles.progressText}>&nbsp;{progressLabel}</p>
			<AnalysisProgressBar pct={pctDone} />
			<div style={{height:'20px'}} />
			<button className={'fs-button ' + styles.tagButton} tabIndex="-1" disabled={true}>ADD BRAND CUES</button>
		</div>
		:
		status === 'tagging' || status === 'work-in-progress' ?
		// video is ready for tagging
		<div>
			<p className={styles.readyText}>Add brand cues to the {isStillImage ? 'image' : 'video'}<br/>to finish the analysis</p>
			<button className={'fs-button ' + styles.tagButton} tabIndex="-1">{status === 'work-in-progress' ? 'CONTINUE TAGGING' : 'ADD BRAND CUES'}</button>
		</div>
		:
		status === 'finished' &&
		// analysis complete
		<>
			<AnalysisScore marg='5px 9px 1px' type="Clarity" score={scoreClarity} />
			<AnalysisScore marg='5px 9px 1px' type="Brand" score={attention[1]} />
			<AnalysisScore marg='5px 9px 1px' type="Comm." score={attention[2]} />
		</>
	)

	const itemMenu = ((showTeamAnalyses && userId === item.user.id) || !showTeamAnalyses) && <AnalysisListItemMenu guid={guid} id={id} status={status} removeItem={removeItem} editDetails={setEditDetails} setAlertTask={setAlertTask} alertObjectRef={alertObjectRef} />

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		<div id={'item-'+id}>
			<ReactCardFlip isFlipped={editDetails} cardZIndex={900-idx} containerStyle={{perspective: '1400px'}} flipSpeedFrontToBack={.8} flipSpeedBackToFront={.8}>
				<div className={styles.item} style={itemStyle} onClick={handleClick} onMouseEnter={onEnter} onMouseLeave={onLeave}>
					<div className={styles.image} style={imageStyle}>
						<div className={styles.titleContainer}>
							<h4 className={styles.project + ' ' + styles.brand}>{brand?.name}</h4>
							<div className={styles.title}>{title}</div>
							<h4 className={styles.project}>{project?.name}</h4>
							<p id={'date'+guid} className={styles.date}>{showTeamAnalyses && <>{item.user.name}&nbsp;&nbsp;|&nbsp;&nbsp;</>}{date}</p>
						</div>
						<div className={styles.infoArea}>
							{infoContent}
						</div>
						{itemMenu}
					</div>
				</div>
				<AnalysisListItemDetails item={item} closeDetails={setEditDetails} setAlertTask={setAlertTask} alertObjectRef={alertObjectRef} isFlipped={editDetails} updateFilters={updateFilters} />
			</ReactCardFlip>
			{alert}
		</div>
	)
}
