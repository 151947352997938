import React, { useContext, useState, useRef } from 'react'
import { APIContext } from '../../../utils/api'
import { GlobalContext } from '../../../utils/globalState'
import styles from './styles.module.css'
import Alert from '../../common/Alert'

// component function
export default function AccountSubscription(props) {

	const { org } = props

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const submittingRef = useRef(false)

	const dateOptions = { year:'numeric', month:'short', day:'numeric' }
	const plan = org.subscriptionPlan
	const calculations = org.subscriptionCalculations
	const subName = plan && <>Flowsam<sup>®</sup> {plan.id === 9 ? 'Trial' : plan.id === 12 ? 'Ad hoc' : plan.id === 13 ? 'Unlimited' : 'Custom'}</>
	const subStart = calculations && new Date(calculations.subStart).toLocaleDateString("en-GB", dateOptions)
	const subRenew = calculations && (calculations.subCancel !== 0 || calculations.subExpired) ? 'n/a' : new Date(calculations.subEnd).toLocaleDateString("en-GB", dateOptions)
	const subExpiration = calculations && (calculations.subCancel !== 0 || calculations.subExpired) && calculations.subEnd > 0 ? new Date(calculations.subEnd).toLocaleDateString("en-GB", dateOptions) : 'n/a'
	const subStatus = calculations ? calculations.subExpired ? 'Expired' : calculations.subCancel !== 0 ? 'Canceled' : 'Active' : ''
	const extraAnalysesLeft = org.freeAnalyses ? org.freeAnalyses : 0
	const priceExclVat = plan?.id === 12 ? numberWithCommas(plan?.config.Price) : numberWithCommas(plan?.config.Monthly_ex_VAT_Monthly_billing) // use Price on Ad hoc or monthly billing on subscriptions
	const videoMaxSecs = plan?.config.Max_film_length
	const videoMaxMb = plan?.config.Max_Mb_per_film
	const cycleName = plan?.config.Calc_cycle_length_month === 12 ? 'yearly' : 'monthly'

	const alert = (alertObjectRef.current !== null && alertTask !== undefined &&
		<Alert
			type={alertObjectRef.current.type}
			title={alertObjectRef.current.title}
			message={alertObjectRef.current.message}
			cancelLabel={alertObjectRef.current.cancelLabel}
			actionLabel={alertObjectRef.current.actionLabel}
			waitingLabel={alertObjectRef.current.waitingLabel}
			useReturnKey={alertObjectRef.current.useReturnKey}
			action={alertTask}
		/>
	)

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()}) // execute alert
	}

	// add thousand separators to number
	function numberWithCommas(val) {
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	// cancel subscription
	function cancelSubscription() {
		alertObjectRef.current = {
			type:'confirm',
			title:'Cancel your flowsam subscription?',
			message:'Your subscription will expire on ' + subRenew + '.\nYou can continue to create analyses until this date, and you can still access your reports after the subscription expires.',
			cancelLabel:'Keep subscription',
			actionLabel:'Cancel subscription',
			waitingLabel:'Canceling...',
			useReturnKey: false
		}
		// show cancel confirm alert
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // submit subscription cancel
				if (!submittingRef.current) {
					submittingRef.current = true
					context.io.socket.post('/api/v1/subscription/cancel', {}, (data, res) => {
						if (res.statusCode === 200) {
							context.io.socket.get('/api/v1/user', (data, res) => {
								setAlertTask() // remove confirm dialogue
								if (res.statusCode === 200) {
									setGlobalState({...globalState, userData:data})
									alertObjectRef.current = { title:'Your flowsam subscription has been canceled and will expire on ' + subRenew + '.', message:'You can continue to create analyses until this date, and you can still access your reports after the subscription expires.' }
									setAlertTask(()=>(action)=>{setAlertTask()}) // execute alert
								} else {
									showAlert('Error refreshing data after succesful subscription cancel: ' + res.error.message) // show error dialogue
								}
								submittingRef.current = false
							})
						} else {
							setAlertTask() // error - remove confirm dialogue
							showAlert('Error canceling subscription: ' + res.error.message) // show error dialogue
							submittingRef.current = false
						}
					})
				}
			} else {
				setAlertTask() // cancel - remove confirm dialogue
				submittingRef.current = false
			}
		})
	}

	// show cancel subscription-button if not cancelled or expired
	const cancelButton = calculations && calculations.subCancel === 0 && !calculations.subExpired && <h3><span style={{fontSize:'18px', opacity:'.7', cursor:'pointer'}} onClick={cancelSubscription}>Cancel</span></h3> 

	// left box/description depending on plan
	const descriptionColumn = (plan.id === 9 ? // trial
		<div className={styles.subColumn}>
			<div className={styles.subColumnHeader}>
				<h3>{subName}</h3>
			</div>
			<div className={styles.subColumnBody} style={{padding:'22px 30px 26px', lineHeight:'26px'}}>
				The free trial includes one flowsam analysis with all features enabled, except industry- and competitor benchmarking, and with watermarked heatmap downloads. For more analyses get <a className={styles.link} href="https://flowsam.ai/features-plans/" target="_blank" rel="noopener noreferrer">Ad hoc</a> or <a className={styles.link} href="https://flowsam.ai/features-plans/" target="_blank" rel="noopener noreferrer">Unlimited</a>.
			</div>
		</div>
		: plan.id === 12 ? // ad hoc
		<div className={styles.subColumn}>
			<div className={styles.subColumnHeader}>
				<h3>{subName}</h3>
			</div>
			<div className={styles.subColumnBody} style={{padding:'22px 30px 26px', lineHeight:'26px'}}>
			<a className={styles.link} href="https://flowsam.ai/features-plans/" target="_blank" rel="noopener noreferrer">Pay-per-use</a> analyses for the occasional need. There is no monthly limit but if you create more than four analyses a month on average the better option will be the <a className={styles.link} href="https://flowsam.ai/features-plans/" target="_blank" rel="noopener noreferrer">Unlimited</a> monthly subscription.
			</div>
		</div>
		: // unlimited/other subscription
		<div className={styles.subColumn}>
			<div className={styles.subColumnHeader}>
				<h3>{subName}</h3>
				{cancelButton}
			</div>
			<div className={styles.subColumnBody} style={{padding:'24px 30px 26px', lineHeight:'22px', display:'flex', justifyContent:'space-between', flexWrap:'wrap', rowGap:'12px'}}>
				<div style={{marginRight:'30px', marginBottom:'-12px'}}>
					<div className={styles.label}>Subscription start</div>
					<div className={styles.subData}>{subStart}</div>
					<div className={styles.label}>Renewal date</div>
					<div className={styles.subData}>{subRenew}</div>
				</div>
				<div style={{marginRight:'30px', marginBottom:'-12px'}}>
					<div className={styles.label}>Status</div>
					<div className={styles.subData}>{subStatus}</div>
					<div className={styles.label}>Expiration date</div>
					<div className={styles.subData}>{subExpiration}</div>
				</div>
				<div style={{marginBottom:'-12px'}}>
					<div className={styles.label}>Analyses spent</div>
					<div className={styles.subData}>222</div> {/* TODO: get number of spent analyses */}
				</div>
			</div>
		</div>
	)

	// right box/description depending on plan
	const priceColumn = (plan.id === 9 ? // trial
		<div className={styles.subColumn}>
			<div className={styles.subColumnHeader}>
				<h3>Price/analysis</h3>
				<h3>Free</h3>
			</div>
			<div className={styles.subColumnBody}>
				<div className={styles.subColumnDetails}>
					<div>Analyses available</div>
					<div>{extraAnalysesLeft}</div>
				</div>
				<div className={styles.subColumnDetails}>
					<div>Max. video length</div>
					<div>{videoMaxSecs} secs</div>
				</div>
				<div className={styles.subColumnDetails}>
					<div>Max. file size</div>
					<div>{videoMaxMb} MB</div>
				</div>
			</div>
		</div>
		: plan.id === 12 ? // ad hoc
		<div className={styles.subColumn}>
			<div className={styles.subColumnHeader}>
				<h3>Price/analysis <span style={{fontSize:'18px', opacity:'.7'}}>(ex.&nbsp;VAT)</span></h3>
				<h3>{priceExclVat}&nbsp;€</h3>
			</div>
			<div className={styles.subColumnBody}>
				<div className={styles.subColumnDetails}>
					<div>Analyses available</div>
					<div>{extraAnalysesLeft}</div>
				</div>
				<div className={styles.subColumnDetails}>
					<div>Max. video length</div>
					<div>{videoMaxSecs} secs</div>
				</div>
				<div className={styles.subColumnDetails}>
					<div>Max. file size</div>
					<div>{videoMaxMb} MB</div>
				</div>
			</div>
		</div>
		: // unlimited/other subscription
		<div className={styles.subColumn}>
			<div className={styles.subColumnHeader}>
				<h3>Price/month <span style={{fontSize:'18px', opacity:'.7'}}>(ex.&nbsp;VAT)</span></h3>
				<h3>{priceExclVat}&nbsp;€</h3>
			</div>
			<div className={styles.subColumnBody}>
				<div className={styles.subColumnDetails}>
					<div>Analyses available</div>
					<div>Unlimited*</div>
				</div>
				<div className={styles.subColumnDetails}>
					<div>Max. video length</div>
					<div>{videoMaxSecs} secs</div>
				</div>
				<div className={styles.subColumnDetails}>
					<div>Max. file size</div>
					<div>{videoMaxMb} MB</div>
				</div>
			</div>
		</div>
	)

	// bottom text for flowsam Unlimited
	const accountText = plan.id !== 9 && plan.id !== 12 && <div className={styles.accountText}>Your {subName} subscription is invoiced ahead at {cycleName} intervals. You can cancel the subscription at any time and it will expire when the pre-paid period ends. Cancelled subscriptions will not be refunded. *You can create as many analyses as you want, taking into account our <a className={styles.link} href="https://flowsam.ai/terms/" target="_blank" rel="noopener noreferrer">fair use policy</a>.</div>

	return (
		<div className={styles.accountElementBack}>
			<div className={styles.subscriptionContainer}>
				<h2>Account type</h2>
				<div className={styles.subColumnsContainer}>
					{descriptionColumn}
					{priceColumn}
				</div>
				{accountText}
			</div>
			{alert}
		</div>
	)
}
