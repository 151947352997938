import React, { useEffect, useContext, useState, useRef } from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import { GlobalContext } from '../../utils/globalState'
import { APIContext } from '../../utils/api'
import Sidebar from '../../components/Sidebar'
import AccountSubscription from '../../components/account/AccountSubscription'
import AccountBilling from '../../components/account/AccountBilling'
import AccountPayment from '../../components/account/AccountPayment'
import AccountInvoices from '../../components/account/AccountInvoices'
import Alert from '../../components/common/Alert'
import styles from './styles.module.css'

// component function
export default function Account() {

	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [stripeIntent, setStripeIntent] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const stripeElementsOptions = { fonts:[{ family: 'Greycliff', src: 'url(https://flowsam.ai/wp-content/uploads/et-fonts/greycliff-webfont.ttf)', weight: '500' }], locale: 'en' }

	const org = globalState.userData?.organization
	const subscription = org && <AccountSubscription org={org} />
	const billing = org && <AccountBilling org={org} />
	const payment = org?.companyStoreId && org?.subscriptionPlan?.id !== 9 && <AccountPayment org={org} /> // don’t show if trial or not in Stripe
	const invoices = org && org?.subscriptionPlan?.id !== 9 && <AccountInvoices org={org} /> // don’t show if trial

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// get stripe key
	useEffect(() => {
		context.io.socket.get('/api/v1/subscription/config', (data, res) => {
			if (res.statusCode === 200) {
				setStripeIntent(loadStripe(data.key))
			} else {
				showAlert('Error getting Stripe data: ' + res.error.message)
			}
		})
	}, [context.io.socket])

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	return (stripeIntent && globalState.userData ?
		<>
			<Sidebar />
			<div className={styles.wrapper}>
				<Elements options={stripeElementsOptions} stripe={stripeIntent}>
					{subscription}
					{billing}
					{payment}
					{invoices}
					{alert}
				</Elements>
			</div>
		</>
		:
		<>
			<Sidebar />
			<div className={styles.wrapper} />
			{alert}
		</>
	)
}
