import React, { useState, useEffect, useRef } from 'react'
import styles from './styles.module.css'

// component function
export default function Alert(props) {

	const { type, title, message, cancelLabel='Cancel', actionLabel='OK', waitingLabel, useReturnKey=true, action } = props
	const [opacity, setOpacity] = useState(0)
	const [waiting, setWaiting] = useState(false)

	// dynamic styles
	const overlayStyle = {
		opacity: opacity
	}

	const titleStyle = {
		marginBottom:'10px',
		color: 'var(--text-color)',
		fontFamily: message ? 'Greycliff demibold' : 'Greycliff'
	}

	const titleWithBreaks = title?.split('\n').map((item, i) => { return <p key={i}>{item}</p> })
	const messageWithBreaks = message?.split('\n').map((item, i) => { return <div key={i} className={styles.message}>{item}</div> })
	const titleDiv = title && <h4 style={titleStyle}>{titleWithBreaks}</h4>
	const messageDiv = message && <div className={styles.messageDiv}>{messageWithBreaks}</div>
	const buttonCancel = type === 'confirm' && !waiting && <button className={'fs-button ' + styles.button + ' ' + styles.cancelButton} tabIndex="-1" id="alert-btn-cancel" disabled={waiting} onClick={e=>performAction(e,false)}>{cancelLabel.toUpperCase()}</button>
	const buttonOk = <button className={'fs-button ' + styles.button} tabIndex="-1" id="alert-btn-ok" disabled={waiting} onClick={e=>performAction(e,true)}>{waiting ? waitingLabel ? waitingLabel.toUpperCase() : actionLabel.toUpperCase() : actionLabel.toUpperCase()}</button>
	const timeoutID = useRef()
	const waitingFlagRef = useRef(false)

	// fade in & set keypress listener
	useEffect(() => {
		timeoutID.current = setTimeout(function() { // delay - otherwise no opacity transition
			setOpacity(1)
		}, 200)
		window.addEventListener('keydown', keyPressed)
		// cleanup on unmount
		return () => { window.removeEventListener('keydown', keyPressed) }
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// handle keypress events
	function keyPressed(e) {
		e.preventDefault()
		if (e.keyCode === 27 && !waitingFlagRef.current) { // escape
			clearTimeout(timeoutID.current)
			action(false)
		} else if (e.keyCode === 13 && useReturnKey && !waitingFlagRef.current) { // enter
			clearTimeout(timeoutID.current)
			action(true)
			setWaiting(true)
		}
	}

	// handle button click
	function performAction(e,b) {
		e.nativeEvent.stopImmediatePropagation()
		setWaiting(true)
		waitingFlagRef.current = true
		action(b)
	}

	return (
		<div id="overlay" className={styles.overlay} style={overlayStyle}>
			<div className={styles.messageContainer}>
				{titleDiv}
				{messageDiv}
				<div className={styles.buttonContainer}>{buttonCancel}{buttonOk}</div>
			</div>
		</div>
	)
}
