import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'

// component function
export default function IntroVideo() {

	const [showFullVideo, setShowFullVideo] = useState(false)

	// init on mount
	useEffect(() => {
		//keyboard esc listener
		window.addEventListener("keydown", keyPressed)
		return () => window.removeEventListener("keydown", keyPressed) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// close video on escape
	function keyPressed(e) {
		e.keyCode === 27 && setShowFullVideo(false) // escape
	}

	// prevent closing video when clicking on it
	function videoClicked(e) {
		e.stopPropagation()
	}

	const fullVideo = (showFullVideo && 
		<div className={styles.overlay} onClick={e=>setShowFullVideo(false)}>
			<video className={styles.fullVideo} controls autoPlay onClick={e=>videoClicked(e)}>
				<source src='https://flowsam.ai/wp-content/uploads/2025/03/how-to-use-flowsam.mp4' type='video/mp4' />
			</video>
		</div>
	)

	return (
		<div className={styles.container}>
			<div className={styles.heading}>Flowsam intro</div>
			<div className={styles.videoTeaser} onClick={e=>setShowFullVideo(true)}>
				<div className={styles.playButton} />
			</div>
			{fullVideo}
		</div>
	)
}
