import React, { useState, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import { mediaTypes, getMediaTypeById } from '../../../utils/mediaTypes'
import InfoBubble from '../../common/InfoBubble'
import styles from './styles.module.css'

// component function
export default function MediaType(props) {

	const { mediaType, setMediaTypeId, mediaRatio } = props
	const [selectedId, setSelectedId] = useState()
	const [height, setHeight] = useState(0)

	mediaType && height === 0 && setHeight('auto') // reveal content if we have a mediaType

	// reset selectedId if mediaType changes
	useEffect(() => {
		setSelectedId(undefined)
		setMediaTypeId(undefined) // inform parent
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaType])

	// reset selectedId if mediaRatio changes to a format that doesn’t match the selected media type
	useEffect(() => {
		const min = getMediaTypeById(selectedId)?.minRatio
		if (min !== undefined && mediaRatio < min) {
			setSelectedId(undefined)
			setMediaTypeId(undefined) // inform parent
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaRatio])

	// media type selected
	function selectMediaType(e) {
		setSelectedId(parseInt(e.currentTarget.id))
		setMediaTypeId(parseInt(e.currentTarget.id)) // inform parent
	}

	// render media type buttons based on dropped file type (video, image)
	const filteredTypes = mediaTypes.filter(t => t.mediaType === mediaType && (t.minRatio === undefined || mediaRatio > t.minRatio)).map((type) => {
		const selected = selectedId === type.id
		const iconStyle = {
			backgroundImage: 'url(' + require('../../../assets/images/media-type-icons/' + (selected ? type.iconHighlight : type.icon)) + ')',
			backgroundPosition: '50% calc(50% + ' + type.vpos + 'px)'
		}
		return (
			<div key={type.id}>
				<div id={type.id} onClick={selectMediaType} className={styles.typeButton + (selected ? ' ' + styles.typeButtonInverse : '')} style={iconStyle}>
					<InfoBubble info={type.info} pos='top' size={140} dist={13} move={-116} inverse={selected} />
				</div>
				<div className={styles.typeLabel}>{type.label}</div>
			</div>
		)
	})

	return (
		<>
			<div className={styles.sectionHeading}>2. Media type</div>
			<AnimateHeight duration={700} height={height}>
				<div className={styles.container}>
					{filteredTypes}
				</div>
			</AnimateHeight>
		</>
	)
}
