import React, { useState, useContext, useEffect } from 'react'
import { APIContext } from '../../../../utils/api'
import { useHistory } from 'react-router-dom'
import styles from './styles.module.css'

// component function
export default function AnalysisListItemMenu(props) {

	const { guid, id, status, removeItem, editDetails, setAlertTask, alertObjectRef } = props
	const context = useContext(APIContext)
	const [menuVisible, setMenuVisible] = useState(false)
	const history = useHistory()

	// init on mount
	useEffect(() => {
		return () => document.removeEventListener('click', hideMenu) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// menu item click handler
	function handleMenuAction(e) {
		e.stopPropagation()
		if (e.currentTarget.id === 'view-report') {
			history.push('/report/' + guid)
		} else if (e.currentTarget.id === 'edit-cues') {
			history.push('/' + guid)
		} else if (e.currentTarget.id === 'delete') {
			alertObjectRef.current = { type:'confirm', title:'Are you sure you want to delete this analysis?\nThis cannot be undone.' }
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					context.io.socket.delete('/api/v1/video/' + guid, (data, res) => {
						if (res.statusCode === 200) {
							removeItem(id)
						} else {
							// TODO: error handling
						}
					})
				}
				setAlertTask() // remove alert
			})
		} else {
			editDetails(true)
		}
	}

	// show menu
	function showMenu(e) {
		e.stopPropagation()
		!menuVisible && document.addEventListener('click', hideMenu)
		setMenuVisible(true)
	}

	// hide menu
	function hideMenu(e) {
		e.stopPropagation()
		document.removeEventListener('click', hideMenu)
		document.getElementById('action-menu') && setMenuVisible(false)
	}

	// menu hidden visible style
	const actionMenuStyle = {
		visibility: menuVisible ? 'visible' : 'hidden'
	}

	const reportMenu = status === 'finished' && <div id="view-report" className={styles.actionMenuItem} onClick={handleMenuAction}>View report</div>
	const editMenu = status === 'finished' && <div id="edit-cues" className={styles.actionMenuItem} onClick={handleMenuAction}>Edit objects</div>
	const detailsMenu = status !== 'analyzing' && <div id="edit-details" className={styles.actionMenuItem} onClick={handleMenuAction}>Edit details</div>

	return (
		<div className={styles.actionContainer}>
		<div id="action-menu" style={actionMenuStyle}>
			{reportMenu}
			{editMenu}
			{detailsMenu}
			<div id="delete" className={styles.actionMenuItem} onClick={handleMenuAction}>Delete</div>
		</div>
			<div className={styles.actionDots} onClick={showMenu}>
				<div className={styles.dot} />
				<div className={styles.dot} />
				<div className={styles.dot} />
			</div>
		</div>
	)
}
