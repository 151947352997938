import React, { useEffect, useContext, useState, useRef } from 'react'
import { APIContext } from './utils/api'
import { GlobalContext } from './utils/globalState'
import { Route, Switch, useHistory } from 'react-router-dom'
import ScrollToTop from './utils/scrollToTop'
import Dashboard from './pages/Dashboard'
import NewAnalysis from './pages/NewAnalysis'
import MyAnalyses from './pages/MyAnalyses'
import Report from './pages/Report'
import Login from './pages/Login'
import Account from './pages/Account'
import NotFound from './pages/NotFound'
import Footer from './components/Footer'
import Terms from './components/Terms'

// component function
export default function App() {

	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const context = useContext(APIContext)
	const history = useHistory()
	const [termsAlertTask, setTermsAlertTask] = useState()
	const globalStateRef = useRef(globalState)

	globalStateRef.current = globalState

	// check if user is logged in and load userData, cue categories & cue types
	useEffect(() => {
		context.io.socket.get('/api/v1/user', (userData, res) => {
			if (res.statusCode === 200) {
				let settings = userData.settings
				// get cueTypeCategories from api
				context.io.socket.get('/api/v1/videocuetypecategory', (categoryData, res) => {
					if (res.statusCode === 200) {
						// check if organization has custom cue types
						const cueTypes = userData?.organization?.settings?.cueTypes
						if (cueTypes && Array.isArray(cueTypes) && cueTypes.length > 0) {
							setGlobalState({ ...globalStateRef.current, cueTypeCategories:categoryData, cueTypes:cueTypes, loggedIn:true, userData:{ ...userData, settings:settings } })
						} else {
							// get default cue types from api
							context.io.socket.get('/api/v1/videocuetype', (cueTypeData, res) => {
								if (res.statusCode === 200) {
									setGlobalState({ ...globalStateRef.current, cueTypeCategories:categoryData, cueTypes:cueTypeData, loggedIn:true, userData:{ ...userData, settings:settings } })
								} else {
									// TODO: error handling
								}
							})
						}
					} else {
						// TODO: error handling
					}
				})
				// show terms accept message on first login
				if (userData.permissionType1 !== '1') { // haven’t accepted terms (if user was added by team manager)
					showTermsAccept()
				}
			} else {
				// get cueTypeCategories from api
				context.io.socket.get('/api/v1/videocuetypecategory', (categoryData, res) => {
					if (res.statusCode === 200) {
						// get default cue types from api // TODO: if we use custom cue types - check if organization of the user who created the analysis has custom cue types !!!!
						context.io.socket.get('/api/v1/videocuetype', (cueTypeData, res) => {
							if (res.statusCode === 200) {
								setGlobalState({ ...globalStateRef.current, cueTypeCategories:categoryData, cueTypes:cueTypeData, loggedIn:false })
								if (history.location.pathname.toLowerCase().indexOf('/shared') < 0 && history.location.pathname.toLowerCase().indexOf('/notfound') < 0) { // go to login unless you’re on publicly accessible paths
									if (true /* V2 DEV TMP */ || !process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
										history.push('/login')
									} else {
										window.location.href = process.env.REACT_APP_PUBLIC_URL
									}
								}
							} else {
								// TODO: error handling
							}
						})
					} else {
						// TODO: error handling
					}
				})
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket, globalState.loggedIn, setGlobalState, history])

	// show terms accept alert
	function showTermsAccept() {
		setTermsAlertTask(()=>(action)=>{ // show terms message
			if (action) { // terms accepted
				context.io.socket.patch('/api/v1/user', { permissionType1:'1' }, (data, res) => { // save terms accepted on user profile
					if (res.statusCode === 200) {
						setGlobalState({ ...globalStateRef.current, userData:{ ...data, permissionType1:'1' } })
					} else {
						// TODO: error handling
					}
				})
			} else { // not accepted - log out
				logout()
			}
			setTermsAlertTask()
		})
	}

	// log out
	function logout() {
		context.io.socket.post('/api/v1/user/logout', (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({ ...globalStateRef.current, loggedIn: false })
				if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
					history.push('/login')
				} else {
					window.location.href = process.env.REACT_APP_PUBLIC_URL
				}
			} else {
				// TODO: error handling
			}
		})
	}

	const termsAlert = termsAlertTask && <Terms action={termsAlertTask} />

	return (
		<>
			<ScrollToTop />
			<Switch>
				<Route path="/shared/:guid/:code" component={Report} />
				<Route path="/shared/:guid" component={NotFound} />
				<Route path="/shared" component={NotFound} />
				<Route path="/report/:guid" component={Report} />
				<Route path="/new-analysis" component={NewAnalysis} />
				<Route path="/my-analyses" component={MyAnalyses} />
				<Route path="/login" component={Login} />
				<Route path="/account" component={Account} />
				<Route path="/notfound" component={NotFound} />
				<Route exact path="/:guid" component={NewAnalysis} />
				<Route exact path="/" component={Dashboard} />
				<Route component={NotFound} />
			</Switch>
			<Footer />
			{termsAlert}
		</>
	)
}
