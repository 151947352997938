import React, { useState, useContext } from 'react'
import { GlobalContext } from '../../../utils/globalState'
import { colors } from '../../../themes/colors'
import Switch from 'react-switch'
import useLocalStorage from '../../../utils/useLocalStorage'
import AssetBar from './AssetBar'
import styles from './styles.module.css'

// component function
export default function ReportAssets(props) {

	const { video } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [reportSettings, setReportSettings] = useLocalStorage('reportAssetSettings', { groupByAsset:true })
	const [showAllBrandAssets, setShowAllBrandAssets] = useState(false)
	const [showAllCommsAssets, setShowAllCommsAssets] = useState(false)
	const listLimit = 5

	// toggle group by asset
	function toggleGroupByAsset() {
		setReportSettings({...reportSettings, groupByAsset:!reportSettings.groupByAsset})
	}
	
	// heading/subheading text based on grouping switch
	const headingText = reportSettings.groupByAsset ? 'Asset attention' : 'Asset type attention'
	const subHeadingText = reportSettings.groupByAsset ? 'Explore how specific assets contribute to ad performance' : 'Explore how specific asset types contribute to ad performance'

	// toggle
	const assetToggle = (
		<div className={styles.switchContainer}>
			<div className={!reportSettings.groupByAsset ? styles.disabled : styles.enabled} onClick={toggleGroupByAsset}>sset</div>
			<Switch
				onChange={toggleGroupByAsset}
				checked={!reportSettings.groupByAsset}
				offColor={colors.switchBackgroundOn}
				offHandleColor={colors.switchKnobOn}
				onColor={colors.switchBackgroundOn}
				onHandleColor={colors.switchKnobOn}
				uncheckedIcon={false}
				checkedIcon={false}
				height={18}
				width={30}
				handleDiameter={14}
				activeBoxShadow=''
				id='group-by-asset-switch'
				/>
			<div className={reportSettings.groupByAsset ? styles.disabled : styles.enabled} onClick={toggleGroupByAsset}>sset type</div>
		</div>
	)
	
	// asset list sorted by score
	const taggedBrandSegments = video.segments.filter(s => globalState.cueTypes.find(ct => ct.id === s.cueType)?.cueTypeCategory === 1).sort((a, b) => (a.scoreAttention < b.scoreAttention) ? 1 : -1)
	const taggedCommsSegments = video.segments.filter(s => globalState.cueTypes.find(ct => ct.id === s.cueType)?.cueTypeCategory === 2).sort((a, b) => (a.scoreAttention < b.scoreAttention) ? 1 : -1)

	// asset type list
	const usedBrandCueTypes = [...new Set(taggedBrandSegments.map(seg => seg.cueType))]
	const usedCommsCueTypes = [...new Set(taggedCommsSegments.map(seg => seg.cueType))]
	const brandCueTypeScores = []
	const commsCueTypeScores = []

	// get score sum for each used brand cue type & sort descending
	usedBrandCueTypes.map((cueType, i) => {
		const brandCueSum = taggedBrandSegments.filter(seg => seg.cueType === usedBrandCueTypes[i]).reduce((a, b) => {return a + b.scoreAttention},0) // TODO: use real metrics
		brandCueTypeScores.push({cueType: cueType, score: brandCueSum})
		return 0
	})
	brandCueTypeScores.sort((a, b) => (a.score < b.score) ? 1 : -1)

	// get score sum for each used comms cue type & sort descending
	usedCommsCueTypes.map((cueType, i) => {
		const commsCueSum = taggedCommsSegments.filter(seg => seg.cueType === usedCommsCueTypes[i]).reduce((a, b) => {return a + b.scoreAttention},0) // TODO: use real metrics
		commsCueTypeScores.push({cueType: cueType, score: commsCueSum})
		return 0
	})
	commsCueTypeScores.sort((a, b) => (a.score < b.score) ? 1 : -1)

	const brandList = (reportSettings.groupByAsset ?
		taggedBrandSegments.map((seg,i) => {
			return <AssetBar key={seg.guid} idKey={i+10000} video={video} asset={seg} type='brand' hidden={!showAllBrandAssets && i >= listLimit} />
		}) :
		brandCueTypeScores.map((obj,i) => {
			const cueTypeObj = { cueType: obj.cueType, scoreAttention: obj.score }
			return <AssetBar key={i} asset={cueTypeObj} type='brand' />
		})
	)

	const commsList = (reportSettings.groupByAsset ?
		taggedCommsSegments.map((seg,i) => {
			return <AssetBar key={seg.guid} idKey={i+20000} video={video} asset={seg} type='comms' hidden={!showAllCommsAssets && i >= listLimit} />
		}) :
		commsCueTypeScores.map((obj,i) => {
			const cueTypeObj = { cueType: obj.cueType, scoreAttention: obj.score }
			return <AssetBar key={i} asset={cueTypeObj} type='comms' />
		})
	)

	const noBrandAssets = brandList.length === 0 && <div className={styles.noAssets}>No brand assets have been tagged</div>
	const noCommsAssets = commsList.length === 0 && <div className={styles.noAssets}>No communication assets have been tagged</div>

	const showAllBrandBtn = (brandList.length > listLimit && reportSettings.groupByAsset &&
		(showAllBrandAssets ?
			<div className={styles.showLimited} onClick={e=>setShowAllBrandAssets(!showAllBrandAssets)}><br/>{'Show only top ' + listLimit}<br/></div> :
			<div className={styles.showAll} onClick={e=>setShowAllBrandAssets(!showAllBrandAssets)}><br/>{'Show all'}<br/></div>
		)
	)
		
	const showAllCommsBtn = (commsList.length > listLimit && reportSettings.groupByAsset &&
		(showAllCommsAssets ?
			<div className={styles.showLimited} onClick={e=>setShowAllCommsAssets(!showAllCommsAssets)}><br/>{'Show only top ' + listLimit}<br/></div> :
			<div className={styles.showAll} onClick={e=>setShowAllCommsAssets(!showAllCommsAssets)}><br/>{'Show all'}<br/></div>
		)
	)

	const assetLabel = reportSettings.groupByAsset ? 'Asset' : 'Asset type'
	const assetTypeLabel = reportSettings.groupByAsset ? 'Asset type' : ''

	return (
		<div className={styles.background}>
			<div className={styles.header}>
				<div className={styles.heading}>
					{headingText}
					<div className={styles.subHeading}>{subHeadingText}</div>
				</div>
				<div className={styles.switchesContainer}>
					{assetToggle}
				</div>
			</div>
			<div className={styles.barsWrapper}>
				<div className={styles.headerRow}>
					<div className={styles.col1} />
					<div className={styles.col2 + ' ' + styles.columnLabel}>{brandList.length > 0 && assetLabel}</div>
					<div className={styles.col3 + ' ' + styles.columnHeading}>Brand Attention</div>
					<div className={styles.col4 + ' ' + styles.columnLabel}>{brandList.length > 0 && assetTypeLabel}</div>
				</div>
				{brandList}
				{showAllBrandBtn}
				{noBrandAssets}
			</div>
			<div className={styles.barsWrapper}>
				<div className={styles.headerRow}>
					<div className={styles.col1} />
					<div className={styles.col2 + ' ' + styles.columnLabel}>{commsList.length > 0 && assetLabel}</div>
					<div className={styles.col3 + ' ' + styles.columnHeading}>Communication Attention</div>
					<div className={styles.col4 + ' ' + styles.columnLabel}>{commsList.length > 0 && assetTypeLabel}</div>
				</div>
				{commsList}
				{showAllCommsBtn}
				{noCommsAssets}
			</div>
		</div>
	)
}
