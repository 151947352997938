// palette
const palette = {
	black: '#000000',
	darkblue: '#2d3544',
	mediumdarkblue: '#3f658d',
	mediumblue: '#5f7c9a',
	purple: '#9d6aa7',
	green: '#39c481',
	brightgreen: '#3ed88b',
	orange: '#ed6c04',
	darkgrey: '#404040',
	mediumgrey: '#afbdcb',
	grey: '#caccd0',
	lightgrey: '#eff1f2',
	lightergrey: '#f5f6f7',
	white: '#ffffff',
	red: '#cc0000'
}

// calculate transparency hex value (takes percent value between 0-1)
const opacity = (pct) => Math.round(pct * 255).toString(16);

// theme
export const colors = {

	// text
	text: palette.darkblue,
	textInverse: palette.white,
	textLabel: palette.darkblue + opacity(.5),
	textLabelBlue: palette.mediumblue,
	textLabelGreen: palette.green,
	textShadow: palette.black + opacity(.5),
	link: palette.green,
	error: palette.red,

	// backgrounds
	background0: palette.white,
	background1: palette.lightergrey,
	background2: palette.lightgrey,
	background3: palette.green,
	background4: palette.mediumblue,
	backgroundDashboardItem: palette.white + opacity(.75),
	backgroundOverlay: palette.black + opacity(.5),
	listItemOverlay: palette.black + opacity(.25),

	// buttons
	buttonBack: palette.mediumblue,
	buttonBackActive: palette.mediumdarkblue,
	buttonText: palette.white,

	// borders
	borderLight: palette.darkblue + opacity(.1),
	borderMedium: palette.darkblue + opacity(.15),
	borderInverse: palette.white + opacity(.4),

	// progress bars
	progressbarFill: palette.mediumblue,
	progressbarBack: palette.lightergrey,
	circlebarBack: palette.lightgrey,

	// metrics
	clarity: palette.purple,
	brand: palette.green,
	comms: palette.orange,

	// icons
	cardIcon: palette.darkblue + opacity(.7),

	// switch
	switchBackgroundOff: palette.grey,
	switchBackgroundOn: palette.green,
	switchKnobOff: palette.lightergrey,
	switchKnobOn: palette.white,

	// drop zone
	dropZoneBorder: palette.darkblue + opacity(.4),
	dropZoneHighlight: palette.lightgrey,
	dropZoneAvatarHighlight: palette.white,

	// form
	radioButtonBack: palette.darkblue + opacity(.12),
	radioButtonSelected: palette.green,
	inputHighlight: palette.white + opacity(.6),
	inputBack: palette.lightergrey,
	inputBorder: palette.lightergrey,
	inputBorder2: palette.darkblue + opacity(.4),
	inputPlaceholder: palette.darkblue + opacity(.6),

	// info bubbles
	infoButtonBack: palette.darkblue + opacity(.12),
	infoButtonText: palette.darkblue + opacity(.5),
	infoBoxBack: palette.lightgrey,
	infoBoxText: palette.darkblue + opacity(.8),

	// alerts
	alertShadow: palette.darkgrey + opacity(.2),
	cancelButtonBack: palette.mediumblue + opacity(.7),

	// report video
	videoShadow: palette.lightgrey,
	cueLabelBackUntagged: palette.white + opacity(.8),
	cueBorderUntagged: palette.white,
	cueBorderUntaggedShadow: palette.darkgrey,
	gazePath: palette.green,

	// reuse video
	loaderbarBack: palette.lightgrey,
	sceneButtonBackActive: palette.lightergrey,
	sceneButtonBack: palette.lightergrey + opacity(.9),

	// analysis cue video
	activeCueOverlay: palette.black,
	drawOverlay: palette.lightgrey + opacity(.4),

	// tag menu
	tagmenuBack: palette.lightgrey,
	tagmenuBackHighlight: palette.white,
	tagmenuText: palette.darkblue + opacity(.9),

	// scroll bars
	scrollbar: palette.darkblue + opacity(.15),
	scrollbarThumb: palette.darkblue + opacity(.2),
	scrollbarThumbHover: palette.darkblue + opacity(.3),

}

// global css variables
document.documentElement.style.setProperty('--text-color', colors.text)
document.documentElement.style.setProperty('--text-inverse-color', colors.textInverse)
document.documentElement.style.setProperty('--text-link-color', colors.link)
document.documentElement.style.setProperty('--text-label-color', colors.textLabel)
document.documentElement.style.setProperty('--text-label-blue', colors.textLabelBlue)
document.documentElement.style.setProperty('--text-label-green', colors.textLabelGreen)
document.documentElement.style.setProperty('--text-shadow-color', colors.textShadow)

document.documentElement.style.setProperty('--bg0-color', colors.background0)
document.documentElement.style.setProperty('--bg1-color', colors.background1)
document.documentElement.style.setProperty('--bg2-color', colors.background2)
document.documentElement.style.setProperty('--bg3-color', colors.background3)
document.documentElement.style.setProperty('--bg4-color', colors.background4)
document.documentElement.style.setProperty('--bg5-color', colors.buttonBackActive)
document.documentElement.style.setProperty('--bg-overlay-color', colors.backgroundOverlay)
document.documentElement.style.setProperty('--bg-list-item-overlay-color', colors.listItemOverlay)
document.documentElement.style.setProperty('--bg-dashboard-item-color', colors.backgroundDashboardItem)
document.documentElement.style.setProperty('--bg-btn-cancel-color', colors.cancelButtonBack)

document.documentElement.style.setProperty('--clarity-color', colors.clarity)
document.documentElement.style.setProperty('--brand-color', colors.brand)
document.documentElement.style.setProperty('--comms-color', colors.comms)

document.documentElement.style.setProperty('--border-light-color', colors.borderLight)
document.documentElement.style.setProperty('--border-medium-color', colors.borderMedium)
document.documentElement.style.setProperty('--border-inverse-color', colors.borderInverse)
document.documentElement.style.setProperty('--border-video-color', colors.videoShadow)

document.documentElement.style.setProperty('--dropzone-border-color', colors.dropZoneBorder)
document.documentElement.style.setProperty('--dropzone-avatar-highlight-color', colors.dropZoneAvatarHighlight)

document.documentElement.style.setProperty('--input-highlight-color', colors.inputHighlight)
document.documentElement.style.setProperty('--input-back-color', colors.inputBack)
document.documentElement.style.setProperty('--input-border-color', colors.inputBorder)
document.documentElement.style.setProperty('--input-border-color-dark', colors.inputBorder2)
document.documentElement.style.setProperty('--input-placeholder-color', colors.inputPlaceholder)

document.documentElement.style.setProperty('--scrollbar-color', colors.scrollbar)
document.documentElement.style.setProperty('--scrollbar-thumb-color', colors.scrollbarThumb)
document.documentElement.style.setProperty('--scrollbar-thumb-hover-color', colors.scrollbarThumbHover)

document.documentElement.style.setProperty('--error-color', colors.error)
document.documentElement.style.setProperty('--alert-shadow-color', colors.alertShadow)

document.documentElement.style.setProperty('--progressbar-fill-color', colors.progressbarFill)
document.documentElement.style.setProperty('--progressbar-back-color', colors.progressbarBack)