import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../../../utils/globalState'
import styles from './styles.module.css'

// component function
export default function EasyTaggingObject(props) {

	const { objects, segment, origSegment, width, height, forceUpdate, toggleSegment, nameMaxChars, objRef1, objRef2 } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [segmentName, setSegmentName] = useState(segment.name.slice(0,nameMaxChars))
	const stroke = segment.cueType === null ? 'dashed' : 'solid'
	const cueTypes = globalState.cueTypes
	const cueTypeCategory = cueTypes.find(cueType => cueType.id === segment.cueType)?.cueTypeCategory
	const cueColor = cueTypeCategory === 1 ? 'var(--brand-color)' : cueTypeCategory === 2 ? 'var(--comms-color)' : 'var(--bg0-color)'

	// dynamic styles
	const objectContainerStyle = {
		width: width,
		height: height,
		margin: -height + 'px auto 0'
	}

	const objectStyle = {
		border: '2px ' + stroke + ' ' + cueColor
	}

	const objectTitleStyle = {
 		color: segment.cueType !== null ? 'var(--text-inverse-color)' : 'var(--text-color)',
 		backgroundColor: cueColor
	}

	// space bar/arrow key video control override listeners
	useEffect(() => {
		document.addEventListener("keydown", focusLabel)
		document.getElementById('label-0')?.addEventListener("keydown", keyOverride)
		return () => {
			document.removeEventListener("keydown", focusLabel)
			document.getElementById('label-0')?.removeEventListener("keydown", keyOverride) // cleanup on unmount
		}
 	},[objects])

	// adjust label input width based on content of hidden span
	useEffect(() => {
		const inputMeasures = Array.from(document.getElementsByClassName('inputMeasure'))
		inputMeasures?.map((m,i) => {
			const w = m.offsetWidth
			document.getElementById('label-'+i).style.width = w + 'px'
			document.getElementById('label-'+i).style.marginLeft = -w + 'px'
			return 0
		})
 	})

	// update object title on prop change
	useEffect(() => {
		setSegmentName(segment.name.slice(0,nameMaxChars))
	}, [segment.name, nameMaxChars])

	// update asset type select width
	useEffect(() => {
		adjustSelectWidth(document.getElementById('select-0'))
 	})

	// override video spacebar, arrows and backspace keys when editing segment name
	function keyOverride(e) {
		(e.keyCode === 13 || e.keyCode === 27) && e.target.blur() // exit input on return or escape key
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// focus label input on return key pressed
	function focusLabel(e) {
		const label = document.getElementById('label-0')
		label && e.keyCode === 13 && document.activeElement !== label && label.select()
	}

	// change segment title in received segment reference
	function saveSegmentName(e) {
		segment.name = e.target.value
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,nameMaxChars) === origSegment.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		setSegmentName(segment.name)
		forceUpdate() // force update parent
	}

	// change segment cueType in received segment reference
	function saveSegmentType(e) {
		segment.cueType = parseInt(e.target.value)
		segment.dirty = segment.id < 0 ? false : segment.cueType === origSegment.cueType && segment.name.slice(0,nameMaxChars) === origSegment.name.slice(0,nameMaxChars) ? false : true // set 'dirty' on segment depending on diff from original value
		forceUpdate() // force update parent
	}

	// adapt select menu width to fit selected text
	function adjustSelectWidth(sel) {
		sel && Array.from(document.getElementsByClassName('selectMeasure'))?.map((m,i) => {
			if (segment.creationMethod !== 'GVI-logo' && segment.cueType === cueTypes.find(ct => ct.defaultForCreationMethod === segment.creationMethod).id) { // don’t select default asset types unless they are detected with the logo API
				document.getElementById('select-'+i).style.width = '23px'
				document.getElementById('select-'+i).selectedIndex = 0
				document.getElementById('select-'+i).style.display = 'block'
			} else {
				m.innerHTML = sel.options[sel.selectedIndex].text.split(' (')[0]
				document.getElementById('selectLabel-'+i).innerHTML = sel.options[sel.selectedIndex].text.split(' (')[0]
				document.getElementById('select-'+i).style.width = m.offsetWidth + 27 + 'px'
				document.getElementById('select-'+i).style.display = 'block'
			}
			return 0
		})
	}

	// object border(s)
	const objectsToDraw = objects.map((o,i) => {

		// object position
		const objWrapperStyle = { top: o.y*height+2, left: o.x*width }
		const objStyle = { ...objectStyle, width: o.w*width, height: o.h*height }
		const objStyle2 = { width: o.w*width, height: o.h*height, marginBottom: -o.h*height } // inner shadow to make dotted border visible on white background

		// asset type menu
		const assetTypeMenu = (cueTypeCategory > 0 &&
			<div className={styles.selectWrapper}>
				<div className={'selectMeasure ' + styles.selectWidth} />
				<select id={'select-'+i} value={segment.cueType === null ? '' : segment.cueType} className={styles.select} onChange={saveSegmentType} title="Asset type">
					<option value="" disabled>Select asset type</option>
					<hr/>
					<optgroup label={globalState.cueTypeCategories[0].name}>
						{cueTypes.filter(ctp => ctp.id !== 10 && ctp.id !== 11 && ctp.id !== 14 && ctp.cueTypeCategory === 1 && ctp.defaultForCreationMethod !== 'GVI-object').map(ct => { // omit GVI-object default since they’re not auto-tagged
							return <option key={'asset-type-brand-' + i + '-' + ct.id} value={ct.id}>{ct.name}</option>
						})}
					</optgroup>
					<optgroup label={'Product'}>
						{cueTypes.filter(ctp => (ctp.id === 10 || ctp.id === 11 || ctp.id === 14) && ctp.cueTypeCategory === 1 && ctp.defaultForCreationMethod !== 'GVI-object').map(ct => { // omit GVI-object default since they’re not auto-tagged
							return <option key={'asset-type-product-' + i + '-' + ct.id} value={ct.id}>{ct.name}</option>
						})}
					</optgroup>
					<hr/>
					<optgroup label={globalState.cueTypeCategories[1].name}>
						{cueTypes.filter(ctp => ctp.id !== 10 && ctp.id !== 11 && ctp.id !== 14 && ctp.cueTypeCategory === 2 && ctp.defaultForCreationMethod !== 'GVI-text').map(ct => { // omit GVI-text default since they’re not auto-tagged
							return <option key={'asset-type-comms-' + i + '-' + ct.id} value={ct.id}>{ct.name}</option>
						})}
					</optgroup>
				</select>
				<div id={'selectLabel-'+i} className={styles.selectLabel + ' ' + (segment.creationMethod !== 'GVI-logo' && segment.cueType === cueTypes.find(ct => ct.defaultForCreationMethod === segment.creationMethod).id && styles.unselected)} style={objectTitleStyle} />
			</div>
		)

		// object edit bar
		const objectBar = (
			<div ref={objRef1} className={styles.barContainer}>
				<div className={'fs-object-title-input inputMeasure ' + styles.measureTitle} style={objectTitleStyle}>{segmentName}</div>
				<input
					id={'label-'+i}
					className={'fs-object-title-input ' + styles.objectTitle}
					style={objectTitleStyle}
					tabIndex="-1"
					spellCheck="false"
					value={segmentName}
					onChange={saveSegmentName}
					maxLength={nameMaxChars}
				/>
				{assetTypeMenu}
			</div>
		)
	
		return (
			<div key={o.id} id={o.id} className={styles.objWrapper} style={objWrapperStyle} >
				<div className={styles.object2} style={objStyle2} />
				<div ref={objRef2} id={'obj'+o.id} className={styles.object} style={objStyle} onClick={toggleSegment} />
				{objectBar}
			</div>
		)
	})

	return (
		<div className={styles.objectContainer} style={objectContainerStyle}>
			{objectsToDraw}
		</div>
	)
}
