import React, { useContext, useState, useRef, useEffect } from 'react'
import { Prompt } from 'react-router'
import Alert from '../../common/Alert'
import DropZoneAvatar from './DropZoneAvatar'
import { APIContext } from '../../../utils/api'
import { GlobalContext } from '../../../utils/globalState'
import selectArrow from '../../../assets/images/select-menu-arrow.svg'
import styles from './styles.module.css'

// component function
export default function AccountBilling(props) {

	const {org} = props
	const storeData = org?.companyStoreData
	const customer = storeData?.customer
	const addr = customer?.address
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [alertTask, setAlertTask] = useState()
	const [countries, setCountries] = useState()
	const [submitting, setSubmitting] = useState(false)
	const alertObjectRef = useRef(null)

	// form data
	const [billingEmail, setBillingEmail] = useState(org.billingEmail ? org.billingEmail : '')
	const [orgName, setOrgName] = useState(org.name ? org.name : '')
	const [address, setAddress] = useState(addr ? addr.line1 : '')
	const [city, setCity] = useState(addr ? addr.city : '')
	const [zip, setZip] = useState(addr ? addr.postal_code : '')
	const [state, setState] = useState(addr ? addr.state : '')
	const [country, setCountry] = useState(addr ? addr.country : '')
	const [taxType, setTaxType] = useState(storeData?.customer_tax_ids?.length > 0 ? storeData.customer_tax_ids[0].type : '')
	const [taxId, setTaxId] = useState(storeData?.customer_tax_ids?.length > 0 ? storeData.customer_tax_ids[0].value : '')
	const [avatar, setAvatar] = useState(org.settings.avatar ? org.settings.avatar : '') // eslint-disable-line no-unused-vars
	const [avatarImage, setAvatarImage] = useState()

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// populate country menu
	const countryMenu = (countries &&
		<div>
			<div className={styles.inputLabel}>Country *</div>
			<select className={styles.inputText + (country ? ' force-white' : '')} id='company-country' style={{background: 'var(--bg0-color) url(' + selectArrow + ') no-repeat 95% 50%'}} value={country} onChange={e=>countrySelected(e.target.value)}>
				<option value="">Please select</option>
				{countries.filter((v,i,a)=>a.findIndex(t=>(t.countryCodeIso3166_2===v.countryCodeIso3166_2))===i).map((coun,i)=><option key={i} value={coun.countryCodeIso3166_2}>{coun.country}</option>) /* unique countries by country code */ }
			</select>
		</div>
	)

	// populate tax type menu
	const taxTypes = countries && country ? countries.filter(coun => coun.countryCodeIso3166_2 === country) : []
	const taxTypeMenu = (taxTypes?.length > 1 &&
		<div>
			<div className={styles.inputLabel}>Tax type *</div>
			<select className={styles.inputText + (country ? ' force-white' : '')} id='company-taxtype' style={{background: 'var(--bg0-color) url(' + selectArrow + ') no-repeat 95% 50%'}} value={taxType} onChange={e=>taxTypeSelected(e.target.value)}>
				<option value="">Please select</option>
				{taxTypes.map((type,i)=><option key={i} value={type.taxIdType}>{type.description}</option>)}
			</select>
		</div>
	)

	// set tax id placeholder
	const example = taxTypes?.length > 0 && taxType ? '(e.g. ' + taxTypes.find(type => type.taxIdType === taxType).example + ')': ''

	// check if form values have changes compared to stored data
	const origValuesRef = useRef(JSON.stringify(valuesObject()))
	const canSubmit = origValuesRef.current !== JSON.stringify(valuesObject()) || avatarImage !== undefined

	// get country/tax list
	useEffect(() => {
		context.io.socket.get('/api/v1/subscription/config', (data, res) => {
			if (res.statusCode === 200) {
				setCountries(data.countryTaxType)
			} else {
				showAlert('Error getting country list: ' + res.error.message)
			}
		})
	}, [context.io.socket])

	// conditional window unload listener
	useEffect(() => {
		canSubmit ? window.addEventListener('beforeunload', leaveWarning) : window.removeEventListener('beforeunload', leaveWarning)
		return () => window.removeEventListener('beforeunload', leaveWarning) // cleanup on unmount
	})

	// warning before leaving if billing info form has changes
	function leaveWarning(e) {
		e.preventDefault()
		e.returnValue = 'If you leave now your billing info changes will be lost'
	}
	
	// country selected
	function countrySelected(countryCode) {
		setCountry(countryCode)
		const countryCodes = countries.filter(coun => coun.countryCodeIso3166_2 === countryCode)
		taxTypeSelected(countryCodes.length === 1 ? countryCodes[0].taxIdType : '') // set taxType value directly if only one or reset tax type menu
	}

	// tax type selected
	function taxTypeSelected(taxType) {
		setTaxType(taxType)
		setTaxId('')
	}

	// return an object with form field values
	function valuesObject() {
		return {
			orgBillingEmail: billingEmail,
			orgName: orgName,
			orgLine1: address,
			orgCity: city,
			orgPostalCode: zip,
			orgState: state,
			orgCountry: country,
			orgTaxIdType: taxType ? taxType : taxTypes?.length === 0 && country ? countries.find(coun => coun.countryCodeIso3166_2 === country).taxIdType : '',
			orgTaxId: taxId,
			avatarImage: avatarImage ? avatarImage : ''
		}
	}

	// validate email
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
		return re.test(String(email).toLowerCase())
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// save billing info
	function saveBillingInfo() {
		if (submitting) return
		const req = valuesObject()
		if (req.orgBillingEmail.trim().length < 1) {
			showAlert('Please enter your ' + (org?.subscriptionPlan?.id !== 9 ? 'billing ' : '') + 'email')
		} else if (!validateEmail(req.orgBillingEmail)) {
			showAlert('Error in ' + (org?.subscriptionPlan?.id !== 9 ? 'billing ' : '') + 'email address')
		} else if (req.orgName.trim().length < 1) {
			showAlert('Please fill in your company name')
		} else if (org?.subscriptionPlan?.id !== 9 && req.orgLine1.trim().length < 1) {
			showAlert('Please fill in your billing address')
		} else if (org?.subscriptionPlan?.id !== 9 && req.orgCity.trim().length < 1) {
			showAlert('Please fill in your city')
		} else if (org?.subscriptionPlan?.id !== 9 && req.orgPostalCode.trim().length < 1) {
			showAlert('Please fill in your zip/postal code')
		} else if (org?.subscriptionPlan?.id !== 9 && req.orgState.trim().length < 1) {
			showAlert('Please fill in your state/region')
		} else if (org?.subscriptionPlan?.id !== 9 && !req.orgCountry) {
			showAlert('Please select your country')
		} else if (org?.subscriptionPlan?.id !== 9 && !req.orgTaxIdType) {
			showAlert('Please select tax type')
		} else if (org?.subscriptionPlan?.id !== 9 && !req.orgTaxId) {
			showAlert('Please enter your company tax ID')
		} else {
			setSubmitting(true)
			context.io.socket.patch('/api/v1/organization', req, (data, res) => { // billing info update request
				if (res.statusCode === 200) { // successful submit
					origValuesRef.current = JSON.stringify(req) // update change-reference object
					setGlobalState({...globalState, userData:{...globalState.userData, organization:data}}) // update organization in globalState
					showAlert(org?.subscriptionPlan?.id === 9 ? 'Company info has been updated' : 'Billing info has been updated')
				} else if (res.statusCode === 410) { // tax id format error
					const example = taxTypes?.length > 0 && taxType ? ' It should look something like this: ' + taxTypes.find(type => type.taxIdType === taxType).example : ''
					showAlert('Please check that your Tax ID is correct.' + example)
				} else { // other error
					console.log("ERROR: " + res.statusCode)
					showAlert('Something went wrong. Please try again or contact flowsam support.')
				}
				setSubmitting(false)
			})
		}
	}

	const infoText = (
		org?.subscriptionPlan?.id === 12 ? <h4 style={{margin: '21px 0 0', padding:'0'}}>The information needed for us to send your invoice. If you have any questions or special needs regarding the billing please don’t hesitate to contact <a style={{color:'var(--text-link-color)',textDecoration:"none"}} href="mailto:sales@flowsam.ai" target="_blank" rel="noopener noreferrer">sales@flowsam.ai</a>.</h4> : // ad hoc
		org?.subscriptionPlan?.id !== 9 && <h4 style={{margin: '21px 0 0', padding:'0'}}>The information needed for us to send your monthly invoice. If you have any questions or special needs regarding the billing please don’t hesitate to contact <a style={{color:'var(--text-link-color)',textDecoration:"none"}} href="mailto:sales@flowsam.ai" target="_blank" rel="noopener noreferrer">sales@flowsam.ai</a>.</h4> // unlimited or custom/other monthly subscription
	)

	const infoSpacer = (
		org?.subscriptionPlan?.id === 9 ? <div style={{height: '37px'}} /> : <div style={{height: '25px', marginBottom:'auto'}} />
	)

	const billingFields = (org?.subscriptionPlan?.id !== 9 && // not trial - display all fields
		<div className={styles.billingFields}>
			<div>
				<div className={styles.inputLabel}>Address *</div>
				<input className={styles.inputText} id='company-address' type="text" spellCheck="false" autoComplete="off" maxLength="200" value={address} onChange={e=>setAddress(e.target.value)} />
			</div>
			<div>
				<div className={styles.inputLabel}>City *</div>
				<input className={styles.inputText} id='company-city' type="text" spellCheck="false" autoComplete="off" maxLength="100" value={city} onChange={e=>setCity(e.target.value)} />
			</div>
			<div>
				<div className={styles.inputLabel}>Zip/postal code *</div>
				<input className={styles.inputText} id='company-zip' type="text" spellCheck="false" autoComplete="off" maxLength="20" value={zip} onChange={e=>setZip(e.target.value)} />
			</div>
			<div>
				<div className={styles.inputLabel}>State/region *</div>
				<input className={styles.inputText} id='company-state' type="text" spellCheck="false" autoComplete="off" maxLength="100" value={state} onChange={e=>setState(e.target.value)} />
			</div>
			{countryMenu}
			{taxTypeMenu}
			<div>
				<div className={styles.inputLabel}>Tax ID *</div>
				<input className={styles.inputText} id='company-tax-id' type="text" placeholder={example} spellCheck="false" autoComplete="off" maxLength="50" value={taxId} onChange={e=>setTaxId(e.target.value)} />
			</div>
		</div>
	)

	return (
		<div className={styles.accountElementBack}>
			<div className={styles.billingContainer}>
				<h2>{org?.subscriptionPlan?.id === 9 ? 'Company info' : 'Billing info'}</h2>
				<div className={styles.billingBox}>
					<div className={styles.billingBoxLeft}>
						{infoText}
						{infoSpacer}
						<DropZoneAvatar avatar={avatar} setAvatarImage={(base64image)=>setAvatarImage(base64image)} />
					</div>
					<div className={styles.billingBoxRight}>
						<div>
							<div className={styles.inputLabel}>{org?.subscriptionPlan?.id === 9 ? 'Email' : 'Billing email'} *</div>
							<input className={styles.inputText} id="company-email" type="email" spellCheck="false" autoComplete="off" maxLength="100" value={billingEmail} onChange={e=>setBillingEmail(e.target.value)} />
						</div>
						<div>
							<div className={styles.inputLabel}>Company *</div>
							<input className={styles.inputText} id='company-name' type="text" spellCheck="false" autoComplete="off" maxLength="50" value={orgName} onChange={e=>setOrgName(e.target.value)} />
						</div>
						{billingFields}
					</div>
				</div>
				<button tabIndex="-1" disabled={!canSubmit || submitting} className="fs-button accountSubmitBtn" onClick={saveBillingInfo}>{submitting ? 'UPDATING...' : org?.subscriptionPlan?.id === 9 ? 'UPDATE COMPANY INFO' : 'UPDATE BILLING INFO'}</button>
			</div>
			<Prompt when={canSubmit} message="If you leave now your billing info changes will be lost" />
			{alert}
		</div>
	)
}
