import React, { useState, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../../utils/globalState'
import styles from './styles.module.css'
import Alert from '../../common/Alert'

// component function
export default function NewAnalysis(props) {

	const { tourRef } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const history = useHistory()
	const [highlight, setHighlight] = useState()
	const [error, setError] = useState()
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const fileInputRef = useRef()
	const analysesExtra = globalState.userData?.organization?.freeAnalyses ? globalState.userData.organization.freeAnalyses : 0
	const maxMb = globalState.userData?.organization?.subscriptionPlan?.config?.Max_Mb_per_film ? globalState.userData.organization.subscriptionPlan.config.Max_Mb_per_film : 1000

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// open file select dialogue
	function openFileDialog() {
		if (globalState.userData?.organization?.subscriptionPlan?.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else {
			fileInputRef.current.click()
		}
	}

	// validate file when added
	function onFileAdded(e) {
		validateFile(e.target.files[0])
	}

	// handle dropped file
	function onDrop(e) {
		e.preventDefault()
		setHighlight(false)
		validateFile(e.dataTransfer.files[0])
	}

	// handle drag enter
	function onDragOver(e) {
		e.preventDefault()
		setHighlight(true)
	}

	// handle drag leave
	function onDragLeave() {
		setHighlight(false)
	}

	// validate file
	function validateFile(file) {
		if (!file) return
		if (globalState.userData?.organization?.subscriptionPlan?.id === 9 && analysesExtra <= 0) { // free trial spent
			showTrialAlert()
		} else {
			let err = null
			err = file.size > maxMb * 1000 * 1000 ? 'Sorry, the video size exceeds the ' + maxMb + ' MB per video allowed in your subscription' : err
			err = (file.type !== 'video/mp4' && file.type.indexOf('image/') !== 0) ? 'Sorry, flowsam only likes .mp4 video or image files' : err
			err ? setError(err) : history.push({pathname: '/new-analysis', state: file})
		}
	}

	// show analyses spent alert
	function showTrialAlert() {
		alertObjectRef.current = { title:'Sorry, you have spent your free trial analysis', message:'Please upgrade to a flowsam subscription to get more analyses', cancelLabel:'Cancel', actionLabel:'Upgrade', type:'confirm'}
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // upgrade
				window.location.href='https://flowsam.ai/features-plans/'
			}
			setAlertTask() // remove confirm dialogue
		})
	}

	const dropZoneClass = highlight ? styles.dropZone + ' ' + styles.dropZoneHighlight : styles.dropZone
	const statusText = highlight ? <h4 className={styles.dropZoneHighlight}>Drop it right here</h4> : error ? <h4 className={styles.dropZoneError}>{error}</h4> : (
		<>
			<div className={styles.icons} />
			<h4>New analysis</h4>
			<p>Drop video or image here</p>
		</>
	)

	return (
		<>
			<div
				ref={tourRef}
				className={dropZoneClass}
				onDragOver={onDragOver}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
				onClick={openFileDialog}>
				<input
					type="file"
					ref={fileInputRef}
					style={{display:'none'}}
					onChange={onFileAdded}
					accept="video/mp4,image/*"
				/>
				{statusText}
			</div>
			{alert}
		</>
	)
}
