import React, { useContext, useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Switch from 'react-switch'
import Alert from '../../common/Alert'
import { APIContext } from '../../../utils/api'
import { GlobalContext } from '../../../utils/globalState'
import { colors } from '../../../themes/colors'
import styles from './styles.module.css'

// component function
export default function UserSettingsMenu(props) {

	const {profileFormChanged, saveUserData, settingsVisible, setSettingsVisible, editingUser, setEditingUser, logout} = props
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const dateOptions = { year:'numeric', month:'short', day:'numeric' }

	const [userName, setUserName] = useState(globalState.userData?.name)
	const [userEmail, setUserEmail] = useState(globalState.userData?.email)
	const [userPassword, setUserPassword] = useState('')
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const history = useHistory()

	const dataRoot = globalState.userData?.organization
	const plan = dataRoot?.subscriptionPlan
	const subscriptionName = plan && <>Flowsam<sup>®</sup> {plan.id === 9 ? 'Trial' : plan.id === 12 ? 'Ad hoc' : plan.id === 13 ? 'Unlimited' : 'Custom'}</>
	const extraAnalysesLeft = dataRoot.freeAnalyses ? dataRoot.freeAnalyses : 0
	const maxFilmLength = plan.config.Max_film_length ? plan.config.Max_film_length : 0
	const maxFilmMb = plan.config.Max_Mb_per_film ? plan.config.Max_Mb_per_film : 0
	const subRenew = dataRoot.subscriptionCalculations && (dataRoot.subscriptionCalculations.subCancel !== 0 || dataRoot.subscriptionCalculations.subExpired) ? 'n/a' : new Date(dataRoot.subscriptionCalculations.subEnd).toLocaleDateString("en-GB", dateOptions)
	const userProfileValueString = globalState.userData?.name + globalState.userData?.email + ''

	// dynamic styles
	const menuBackStyle = {
		left: settingsVisible ? '70px' : '-350px'
	}

	// get fresh user-/account data
	useEffect(() => {
		context.io.socket.get('/api/v1/user', (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({...globalState, userData:data})
				setUserName(data.name)
				setUserEmail(data.email)
			} else {
				showAlert('Error getting user data: ' + res.error.message)
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.io.socket, settingsVisible])

	// key overrides so input doesn’t interfere with other key listeners
	useEffect(() => {
		document.getElementById('name').addEventListener("keydown", keyOverride)
		document.getElementById('name').addEventListener("keyup", keyOverride)
		document.getElementById('email').addEventListener("keydown", keyOverride)
		document.getElementById('email').addEventListener("keyup", keyOverride)
		document.getElementById('passw').addEventListener("keydown", keyOverride)
		document.getElementById('passw').addEventListener("keyup", keyOverride)
		return () => {
			document.getElementById('name').removeEventListener("keydown", keyOverride) // cleanup on unmount
			document.getElementById('name').removeEventListener("keyup", keyOverride)
			document.getElementById('email').removeEventListener("keydown", keyOverride)
			document.getElementById('email').removeEventListener("keyup", keyOverride)
			document.getElementById('passw').removeEventListener("keydown", keyOverride)
			document.getElementById('passw').removeEventListener("keyup", keyOverride)
		}
 	},[])

	// override spacebar, arrows and backspace key listeners when editing user profile
	function keyOverride(e) {
		e.keyCode !== 27 && // allow escape
		e.keyCode === 8|32|37|39 && e.stopPropagation()
	}

	// toggle newsletter signup
	function toggleNewsletter() {
		const newsletterSignup = globalState.userData?.permissionType2 && globalState.userData?.permissionType2 === '1' ? '0' : '1'
		const newStateObj = {...globalState, userData:{...globalState.userData, permissionType2:newsletterSignup}}
		setGlobalState(newStateObj)
		saveUserSettings({permissionType2:newStateObj.userData.permissionType2})
	}

	// show subscription info box
	function showInfo(e) {
		document.getElementById('infoBox').style.opacity = 1
	}

	// hide subscription info box
	function hideInfo(e) {
		document.getElementById('infoBox').style.opacity = 0
	}

	// save user settings
	function saveUserSettings(userDataProp) {
		context.io.socket.patch('/api/v1/user', userDataProp, (data, res) => {
			if (res.statusCode !== 200) {
				showAlert('Error saving user settings: ' + res.error.message)
			}
		})
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	let allowSubmit = true

	// save user data
	saveUserData.current = (e, closing=false) => {
		if (allowSubmit && profileFormChanged.current) { // save if profile data has changed and we’re not already saving
			if (!validateEmail(userEmail)) {
				showAlert('Error in email address')
			} else if (userName.trim().length < 1) {
				showAlert('Name can not be empty')
			} else if (userPassword.length > 0 && userPassword.length < 8) {
				showAlert('Password must be at least 8 characters')
			} else if (userPassword.indexOf(' ') > -1) {
				showAlert('Password can not contain spaces')
			} else { // all ok
				allowSubmit = false
				const request = {
					email: userEmail,
					name: userName.trim(),
					password: userPassword !== '' ? userPassword : globalState.userData?.password
				}
				// send user data
				context.io.socket.patch('/api/v1/user', request, (data, res) => {
					if (res.statusCode === 200) {
						profileFormChanged.current = false
						setGlobalState({...globalState, userData:data})
						setEditingUser(false)
						closing && setSettingsVisible(false)
					} else if (res.statusCode === 409) {
						showAlert('Email address is already in use by another account')
					} else {
						showAlert('Error saving user data: ' + res.error.message)
						setEditingUser(false)
					}
					allowSubmit = true
				})
			}
		}
	}

	// validate email
	function validateEmail(email) {
		const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i //eslint-disable-line
		return re.test(String(email).toLowerCase())
	}

	// update form values
	function updateFormValue(e) {
		e.target.id === 'name' && setUserName(e.target.value)
		e.target.id === 'email' && setUserEmail(e.target.value)
		e.target.id === 'passw' && setUserPassword(e.target.value)
		profileFormChanged.current = userProfileValueString !== (document.getElementById('name').value + document.getElementById('email').value + document.getElementById('passw').value)
		profileFormChanged.current ? document.getElementById('saveButton').classList.remove(styles.smallButtonDisabled) : document.getElementById('saveButton').classList.add(styles.smallButtonDisabled)
	}

	// go to account settings
	function goAccountSettings() {
		history.push('/account')
		setSettingsVisible(false)
	}

	// edit account button
	const editAccountButton = (
		<div className={styles.smallButton} onClick={goAccountSettings}>Edit</div>
	)

	// edit user/save user button
	const editSaveButton = (editingUser ?
		<div id='saveButton' className={styles.smallButton + ' ' + styles.smallButtonDisabled} onClick={saveUserData.current}>Save</div> :
		<div className={styles.smallButton} onClick={e=>setEditingUser(true)}>Edit</div>
	)

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	const renewalText = (plan.id !== 9 && plan.id !== 12 &&
		<>
			<div className={styles.label}>Renewal date</div>
			<h4 className={styles.value}>
				{subRenew}
			</h4>
		</>
	)

	return (
		<div className={styles.menuBack} style={menuBackStyle}>
			<div className={styles.sectionContainer} style={{borderTop:'none', paddingTop:0}}>
				<div style={{display:'flex'}}>
					<h3 className={styles.heading}>Account</h3>
					{editAccountButton}
				</div>
				<div style={{display:'flex', flexWrap:'wrap'}}>
					<div style={{marginRight:'50px'}}>
						<div className={styles.label}>Company</div>
						<h4 className={styles.value}>{dataRoot.name}</h4>
					</div>
					<div style={{marginRight:'40px'}}>
						<div className={styles.label}>Account type</div>
						<div style={{display:'flex'}} onMouseEnter={showInfo} onMouseLeave={hideInfo}>
							<h4 className={styles.value} style={{cursor:'pointer'}} onClick={goAccountSettings}>{subscriptionName}</h4>
							<div id='info' className={styles.infoButton}>i</div>
						</div>
						<div id='infoBox' className={styles.infoBox}>
							•&nbsp;&nbsp;Analyses available: {plan.id === 9 || plan.id === 12 ? extraAnalysesLeft : 'Unlimited'}<br/>
							•&nbsp;&nbsp;Max. video length: {maxFilmLength} secs<br/>
							•&nbsp;&nbsp;Max. file size: {maxFilmMb} MB<br/>
						</div>
						{renewalText}
					</div>
				</div>
			</div>
			<div className={styles.sectionContainer}>
				<div style={{display:'flex'}}>
					<h3 className={styles.heading}>User</h3>
					{editSaveButton}
				</div>
				<form>
					<div className={styles.label}>Name</div>
					<input
						id='name'
						className={'fs-input-text ' + styles.input + (editingUser ? '' : ' ' + styles.inputDisabled)}
						tabIndex='1'
						spellCheck='false'
						value={userName}
						onChange={updateFormValue}
						maxLength='100'
						readOnly={!editingUser}
						autoComplete='off'
					/>
					<div className={styles.label}>Email</div>
					<input
						id='email'
						className={'fs-input-text ' + styles.input + (editingUser ? '' : ' ' + styles.inputDisabled)}
						tabIndex='2'
						spellCheck='false'
						value={userEmail}
						onChange={updateFormValue}
						maxLength='150'
						readOnly={!editingUser}
						autoComplete='off'
					/>
					<div className={styles.label}>Password</div>
					<input
						id='passw'
						type='password'
						className={'fs-input-text ' + styles.input + (editingUser ? '' : ' ' + styles.inputDisabled)}
						tabIndex='3'
						spellCheck='false'
						value={userPassword}
						onChange={updateFormValue}
						placeholder={editingUser ? 'Change password' : '••••••••'}
						maxLength='100'
						readOnly={!editingUser}
						autoComplete='new-password'
					/>
				</form>
			</div>
			<div className={styles.sectionContainer}>
				<div className={styles.switchContainer} style={{marginTop: '3px'}}>
					<Switch
						onChange={toggleNewsletter}
						checked={globalState.userData?.permissionType2 ? globalState.userData?.permissionType2 === '1' : false}
						offColor={colors.switchBackgroundOff}
						offHandleColor={colors.switchKnobOff}
						onColor={colors.switchBackgroundOn}
						onHandleColor={colors.switchKnobOn}
						uncheckedIcon={false}
						checkedIcon={false}
						height={20}
						width={36}
						handleDiameter={16}
						activeBoxShadow=''
						id='newsletter-switch'
					/>
					<h4 className={styles.switchLabel} onClick={toggleNewsletter}>Newsletter</h4>
				</div>
			</div>
			<div className={styles.sectionContainer}>
				<h4 className={styles.value} style={{cursor:'pointer', display:'inline'}} onClick={logout}>Log out</h4>
			</div>
			{alert}
		</div>
	)
}
