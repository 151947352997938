import React from 'react'
import styles from './styles.module.css'

// component function
export default function AnalysisScore(props) {

	const { marg=0, dia=80, stw=11, fsize=18, lsize='inherit', type, score } = props

	// dynamic styles
	const scoreWrapperStyle = {
		margin: marg
	}

	const scoreStyle = {
		width: dia + 'px',
		height: dia + 'px'
	}

	const circleBgStyle = {
		strokeWidth: stw
	}

	const scoreTextStyle = {
		fontSize: fsize + 'px'
	}

	const labelTextStyle = {
		fontSize: lsize + 'px'
	}

	const circleStyle = {
		strokeWidth: stw,
		//stroke: Math.round(score) < limit1 ? theme.analysisScoreLowColor : Math.round(score) < limit2 ? .analysisScoreMediumColor : theme.analysisScoreHighColor,
		stroke: type?.toLowerCase().indexOf("clarity") > -1 ? 'var(--clarity-color)' : type?.toLowerCase().indexOf("brand") > -1 ? 'var(--brand-color)' : 'var(--comms-color)', // TODO: NEW METRIC TYPES/SCORES
		display: (score === 0) ? 'none' : 'block'
	}

	const dashStyle = {
		...circleStyle,
		opacity: score ? 1 : 0
	}

	const circleValues = "M"+(dia/2)+" "+(stw/2)+" a "+((dia-stw)/2)+" "+((dia-stw)/2)+" 0 0 1 0 "+(dia-stw)+" a "+((dia-stw)/2)+" "+((dia-stw)/2)+" 0 0 1 0 -"+(dia-stw)
	const strokeLength = Math.floor(score/100 * Math.PI * (dia-stw)) - stw/2
	const dashValue = strokeLength < 0 ? 0 : strokeLength
	const value = score ? Math.round(score) : 0

	const label = type ? type.split('\n').map((item, i) => {	// line break hack
		return <p key={i}>{item}</p>
	}) : null

	return (
		<div>
			<div className={styles.scoreWrapper} style={scoreWrapperStyle}>
				<svg style={scoreStyle}>
					<path className={styles.circleBg} style={circleBgStyle} d={circleValues} />
					<path className={styles.circle} style={dashStyle} strokeDasharray={dashValue+',1000'} d={circleValues} />
					<text className={styles.scoreText} style={scoreTextStyle} x={dia/2} y={dia/2+fsize/3}>{value}</text>
				</svg>
			</div>
			<div className={styles.labelText} style={labelTextStyle}>{label}</div>
		</div>
	)
}
